export const validForm = (opros) => {

    let mist = 0

    if (opros.company_name.length == 0) mist++
    if (opros.product_category_id == '0') mist++
    if (opros.product_id == '0') mist++
    if (opros.package_id == '0') mist++
    if (!Number.isInteger(parseInt(opros.razmer_upak_length)) || opros.razmer_upak_length <= 0) mist++
    if (!Number.isInteger(parseInt(opros.razmer_upak_width)) || opros.razmer_upak_width <= 0) mist++
    if (!Number.isInteger(parseInt(opros.razmer_upak_height)) || opros.razmer_upak_height <= 0) mist++
    if (!Number.isInteger(parseInt(opros.productivity)) || opros.productivity <= 0) mist++

    if ((opros.productivity_unit == 'шт / смена' || opros.productivity_unit == 'шт / сутки') && opros.col_work_hour == '') mist++

    if (mist == 0) 
        return true
    return false

}