import React, {useState, useContext} from 'react'
import { useParams } from 'react-router-dom'
import PrintAutoclave from './PrintAutoclave.js'
import DeliveryOpros from './DeliveryOpros.js'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { getOpros } from '../../http/oprosAPI.js'
import {
    getNameAutoclave,
    getArrayDiametr,
    colNeedAutoclave,
    getTotalPriceAutoclave,
    colProductInBasket,
    getMaxWaitingTime,
    getTotalWeightBasket,
    checkUserSetting,
} from './calculateFunction.js'
import { opros_default, opros_user_setting_default } from './oprosDefault.js'
import { type_d, col_basket_array } from '../../utils/catalog.js'
import { getOprosUserSetting, saveOprosUserSetting } from '../../http/oprosAPI.js'
import { 
    getPriceAutoclave, 
    getBusketDimension, 
    getAccessories, 
    getPriceAccessories,
    getWeightAccessories
} from '../../http/catalogAPI.js'
import {Context} from '../../index.js'
import { observer } from 'mobx-react'


const CalculateItog = observer(() => { 

    const { user } = useContext(Context)
    const { app } = useContext(Context)

    const [opros,setOpros] = useState(opros_default)
    const [opros_user_setting,setOprosUserSetting] = useState(opros_user_setting_default)
    const [nameAutoclave,setNameAutoclave] = useState('')
    const {opros_id} = useParams()
    const [arrayDiametr,setArrayDiametr] = useState([])
    const [content,setContent] = useState([])
    const [param,setParam] = useState({})
    const [loaded,setLoaded] = useState(false)
    const [tempParam,setTempParam] = useState(false)
    const [tempParam2,setTempParam2] = useState(false)

    
    
    React.useEffect(async () => {

        let opros_data = await getOpros(opros_id)
        setOpros(opros_data)

        // Устанавливаем пользовательские настройки
        let opros_user_setting = await getOprosUserSetting(opros_id)
        if (Object.keys(opros_user_setting).length > 0) {
            setOprosUserSetting(opros_user_setting)
        }
        opros_user_setting.opros_id = opros_id
        
        // Формируем название автоклава (заголовок)
        setNameAutoclave(getNameAutoclave(opros_data, app))

        // Формируем массив с диаметрами для текущего автоклава
        let arrayDiametrData = getArrayDiametr(opros_data,type_d)['array_diametr']
        let layout_id = getArrayDiametr(opros_data,type_d)['layout_id']
        let type_id = getArrayDiametr(opros_data,type_d)['type_id']

        setArrayDiametr(arrayDiametrData)

        // Подгружаем цены на автоклавы
        let arr_price_autoclave = await getPriceAutoclave()

        // Подгружаем цены на комплектующие и массив с комплектующими
        let arr_price_accessories = await getPriceAccessories()
        let arr_weight_accessories = await getWeightAccessories()
        let arr_accessories = await getAccessories()

        // Размеры корзин
        let arr_busket_dimension = await getBusketDimension()

        setParam({
            layout_id:layout_id,
            type_id:type_id,
            arr_price_autoclave:arr_price_autoclave,
            arr_price_accessories:arr_price_accessories,
            arr_weight_accessories:arr_weight_accessories,
            arr_accessories:arr_accessories,
            arr_busket_dimension:arr_busket_dimension,
            user:user,
            opros_user_setting:opros_user_setting,
        })
        
        setLoaded(true)

    },[app.lang])

    const [bestPrice,setBestPrice] = useState({
        diametr: 0,
        col_basket: 0,
        col_autoclave: 0,
        price: 0,
    })

    let best_price

    if (loaded === true) {

        best_price = {
            diametr: 0,
            col_basket: 0,
            price: 0,
        }

        // Ищем самую низку цену
        arrayDiametr.map((diametr)=>{
            col_basket_array.map((col_basket)=>{

                let col_product_in_basket = colProductInBasket(diametr,opros,param)
                let ob_col_autoclave = colNeedAutoclave(diametr,col_basket,opros,param)
                let max_waiting_time = getMaxWaitingTime(diametr,col_basket,opros,param)
                let total_weight_basket = getTotalWeightBasket(diametr,opros,param)
                let total_data_price = getTotalPriceAutoclave(diametr,col_basket,opros,param,ob_col_autoclave)
                let total_price = total_data_price.price_autoclave

                let param_one = {}

                for (let key in param) {
                    param_one[key] = param[key]
                }

                param_one['col_autoclave'] = ob_col_autoclave.length
                param_one['max_waiting_time'] = max_waiting_time
                param_one['col_product_in_basket'] = col_product_in_basket
                param_one['total_weight_basket'] = total_weight_basket
                param_one['diametr'] = diametr
                param_one['col_basket'] = col_basket
                param_one['total_price'] = total_price


                total_weight_basket = total_weight_basket.toFixed(1)


                let check_user_setting = checkUserSetting(opros, param_one)

                
            

                if (check_user_setting) {
                    let total_price = 0
                    let price_one_autoclave = total_data_price.price_one_autoclave
                    if (price_one_autoclave !== 0) {
                        total_price = total_data_price.price_autoclave
                        if (best_price.price === 0 || best_price.price !== 0 && best_price.price > total_price) {
                            best_price.diametr = diametr
                            best_price.col_basket = col_basket
                            best_price.price = total_price
                            best_price.col_autoclave = ob_col_autoclave.length
                        } 
                    }
 
                }
            })
        })

        setBestPrice(best_price)

        // Формируем контент
        let content_temp = []
        arrayDiametr.map((diametr)=>{
            let row = (
                <tr key={'tr_diametr_'+diametr}>
                    <td>{diametr}</td>
                    {col_basket_array.map((col_basket)=>{
                       
                        return (
                            <td key={'col_b_'+col_basket}>
                                <PrintAutoclave 
                                    diametr={diametr} 
                                    col_basket={col_basket} 
                                    opros_user_setting={opros_user_setting} 
                                    opros={opros} 
                                    param={param} 
                                    best_price={best_price}>
                                </PrintAutoclave>
                            </td>
                        )

                    })}
                </tr>
            )
            content_temp.push(row) 
        })
        setContent(content_temp)

        setLoaded(false)

    }

    const onBlurOprosUserSetting = async (e) => {

        setParam({
            ...param,
            opros_user_setting:opros_user_setting,
        })

        await saveOprosUserSetting(opros_id,opros_user_setting)

        setLoaded(true)

    }


    const onChangeOprosUserSetting = (e) => {

        setOprosUserSetting({
            ...opros_user_setting,
            [e.target.name]: e.target.value
        })

    }

    let BreadCrumbArray = [
        {
            link: '/calculate-list',
            name: app.translate['calculate_list_title'],
            icon: 'bx bx-home-circle',
        },
        {
            link: '/calculate-data/'+opros_id,
            name: app.translate['start_data'],
            icon: 'bx bx-list-check',
        },
        {
            link: '/calculate-itog/'+opros_id,
            name: app.translate['result_calculate'],
            icon: 'bx bx-calculator',
        }
    ]
    



    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={app.translate['calculated_data']} data={BreadCrumbArray}/>

                        <div className="card">
                            <div className="card-body">

                                <div className="card-title">
                                    <h5 className="mb-0">{nameAutoclave}</h5>
                                </div>
                                <br/>

                                <div className="user-setting-itog">
                                    <div>
                                        {app.translate['calculate_itog_col_autoclave_min']} <input onBlur={onBlurOprosUserSetting} onChange={onChangeOprosUserSetting} value={opros_user_setting.col_autoclave_min} name="col_autoclave_min" type="number"/> {app.translate['calculate_itog_col_autoclave_unit']} - {app.translate['calculate_itog_col_autoclave_max']} <input onBlur={onBlurOprosUserSetting} onChange={onChangeOprosUserSetting} value={opros_user_setting.col_autoclave_max} name="col_autoclave_max" type="number"/> {app.translate['calculate_itog_col_autoclave_unit']}
                                    </div>
                                    <div>
                                        {app.translate['calculate_itog_d_autoclave']}: {app.translate['calculate_itog_d_auto_from']} <input onBlur={onBlurOprosUserSetting} onChange={onChangeOprosUserSetting} value={opros_user_setting.diametr_min} name="diametr_min" type="number"/> {app.translate['calculate_itog_d_unit']} - {app.translate['calculate_itog_d_auto_before']} <input onBlur={onBlurOprosUserSetting} onChange={onChangeOprosUserSetting} value={opros_user_setting.diametr_max} name="diametr_max" type="number"/> {app.translate['calculate_itog_d_unit']}
                                    </div>
                                    <div>
                                        {app.translate['calculate_itog_max_waiting_time']}: <input onBlur={onBlurOprosUserSetting} onChange={onChangeOprosUserSetting} value={opros_user_setting.max_waiting_time} name="max_waiting_time" type="number"/> {app.translate['calculate_itog_max_waiting_time_unit']}
                                    </div>
                                    <div>
                                        {app.translate['calculate_itog_basket_weight']}: {app.translate['calculate_itog_basket_weight_before']} <input onBlur={onBlurOprosUserSetting} onChange={onChangeOprosUserSetting} value={opros_user_setting.basket_weight} name="basket_weight" type="number"/> {app.translate['calculate_itog_basket_weight_unit']}
                                    </div>
                                    <div>
                                        {app.translate['calculate_itog_col_basket']}: {app.translate['calculate_itog_col_basket_min']} <input onBlur={onBlurOprosUserSetting} onChange={onChangeOprosUserSetting} value={opros_user_setting.col_basket_min} name="col_basket_min" type="number"/> {app.translate['calculate_itog_col_basket_unit']} - {app.translate['calculate_itog_col_basket_max']} <input onBlur={onBlurOprosUserSetting} onChange={onChangeOprosUserSetting} value={opros_user_setting.col_basket_max} name="col_basket_max" type="number"/> {app.translate['calculate_itog_col_basket_unit']}
                                    </div>
                                </div>

                                <br/>
                                
                                <div style={{backgroundColor: 'rgb(245,245,245)', color: 'rgb(100,100,100)', padding: '10px 25px', fontSize: 14}}>
                                    <i style={{color: 'rgb(255,50,0)'}} class='bx bxs-hot'></i> &nbsp; {app.translate['calculate_itog_best_price']}: {bestPrice.price} € &nbsp; | &nbsp; {app.translate['calculate_itog_col_auto']}: {bestPrice.col_autoclave} {app.translate['calculate_itog_col_auto_unit']} &nbsp; | &nbsp; {app.translate['calculate_itog_diametr']}: {bestPrice.diametr} {app.translate['calculate_itog_diametr_unit']} &nbsp; | &nbsp; {app.translate['calculate_itog_col_basket']}: {bestPrice.col_basket} {app.translate['calculate_itog_col_basket_unit']}
                                </div>

                                <br/>

                                <div className="overFlowAuto">

                                    <table className="tableCalculateItog">
                                        <tbody>
                                            <tr>
                                                <td rowSpan="2">
                                                    {app.translate['calculate_itog_diametr']}, {app.translate['calculate_itog_diametr_unit']}
                                                </td>
                                                <td colSpan="8">
                                                    {app.translate['calculate_itog_col_basket']}, {app.translate['calculate_itog_col_basket_unit']}
                                                </td>
                                            </tr>

                                            <tr>
                                                {col_basket_array.map((col_basket)=>{
                                                    return <td key={'title_basket_'+col_basket}>{col_basket}</td>
                                                })}
                                            </tr>

                                            {content.map((row)=>{
                                                return row
                                            })}
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>


                        

                        <DeliveryOpros opros={opros}></DeliveryOpros>


                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
})

export default CalculateItog