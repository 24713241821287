import {$authHost, $host} from './index.js'

export const get_product_category = async () => {

    const {data} = await $authHost.get('api/product/product_category', {})

    return data

}

export const get_package_list = async () => {

    const {data} = await $authHost.get('api/product/package_list', {})

    return data

}

export const get_product_list = async (product_category_id) => {

    const {data} = await $authHost.get('api/product/product_list', {
        params: {
            product_category_id:product_category_id
        }
    })

    return data

}

export const getFullOprosData = async () => {

    const {data} = await $authHost.get('api/product/get_full_opros_data');

    return data

}
