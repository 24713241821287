import { useState } from 'react'

import { 
    setLang
 } from '../../../http/catalogAPI.js'

const InputLang = (props) => {

    const [key, setKey] = useState(props.kkey)
    const [value, setValue] = useState(props.value)
    let lang_id = props.lang_id

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onBlur = async (e) => {
        await setLang(lang_id,key,e.target.value)
    }

    return (
        <input
            type="text"
            value={value}
            onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur(e)}
            className="form-control form-control-sm"
        />
    )
}

export default InputLang