import BreadCrumb from "./BreadCrumb"
import Footer from "./Footer"
import Header from "./Header"
import { 
    getLang,
    removeLang,
 } from '../../http/catalogAPI.js'
import { useEffect, useState, useContext } from "react"
import InputLang from "./view/InputLang"
import InputLangAdd from "./view/InputLangAdd"
import { observer } from 'mobx-react'
import { Context } from '../../index.js'

const LangSetting = observer(() => {

    const { app } = useContext(Context)
    
    const [arrLang,setArrLang] = useState([])
    const [reload,setReload] = useState(true)

    let BreadCrumbArray = [
        {
            link: '/calculate-list',
            name: app.translate['calculate_list_title'],
            icon: 'bx bx-calculator',
        }
    ]

    const updateLang = () => setReload(!reload)    

    const removeLangRow = async (lang_id) => {
        await removeLang(lang_id)
        setReload(!reload)
    }

    
    useEffect(async () => {
        let data = await getLang()
        setArrLang(data)
    }, [reload])


    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={ app.translate['menu_lang_files'] } data={BreadCrumbArray}/>
                    
                        <div style={{overflow:'auto'}}>
                            <div className="card">
                                
                                <table className="tableLangSetting">
                                    <thead>
                                        <tr>
                                            <th>KEY</th>
                                            <th>RU</th>
                                            <th>EN</th>
                                            <th>ES</th>
                                            <th>Comment</th>
                                            <th></th>
                                        </tr>

                                        <InputLangAdd updateLang={updateLang}/>

                                    </thead>
                                    
                                    <tbody>

                                        {arrLang.map((lang)=>{
                                            return (
                                                <tr>
                                                    <td><InputLang key={lang.lang_id} kkey="key" lang_id={lang.lang_id} value={lang.key}/></td>
                                                    <td><InputLang key={lang.lang_id} kkey="name_ru" lang_id={lang.lang_id}  value={lang.name_ru}/></td>
                                                    <td><InputLang key={lang.lang_id} kkey="name_en" lang_id={lang.lang_id}  value={lang.name_en}/></td>
                                                    <td><InputLang key={lang.lang_id} kkey="name_es" lang_id={lang.lang_id}  value={lang.name_es}/></td>
                                                    <td><InputLang key={lang.lang_id} kkey="comment" lang_id={lang.lang_id}  value={lang.comment}/></td>
                                                    <td style={{textAlign:'center'}}><span onClick={() => removeLangRow(lang.lang_id)} className="bx bxs-trash" style={{color: 'red', cursor: 'pointer'}}></span></td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>

                            </div>
                        </div>

                        
                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
})

export default LangSetting