import { useEffect, useState } from 'react'
import { prettryNumber } from '../../utils/fnc'

const InputNumberPretty = (props) => {

    const [value,setValue] = useState(Number(props.value).toLocaleString())

    const onBlur = (e) => {
        props.onBlur(e,props.key_ob)
    }

    const onChange = (e) => {
        props.onChange(e,props.key_ob)
    }

    useEffect(() => {
        setValue(Number(props.value).toLocaleString())
    },[props])

    return (
        <input
            type="text"
            step="any"
            className={props.className} 
            value={value} 
            onBlur={(e) => onBlur(e)} 
            onChange={(e) => onChange(e)}
        />
    )
}

export default InputNumberPretty