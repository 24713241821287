import React, {useState, useContext} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { CALCULATE_OPROS_ROUTER, CALCULATE_DATA_ROUTER } from '../../utils/consts.js'
import { getFullOprosData } from '../../http/productAPI.js'
import { saveOpros, getOpros } from '../../http/oprosAPI.js'
import { validForm } from './validationOpros.js' 
import { opros_default } from './oprosDefault.js'
import { observer } from 'mobx-react'
import { 
    type_autovlave_list,
    col_door_list, 
    type_podacha_water_list,
    type_ukladka_list,
    complect_basket_list,
    complect_telejka_list
} from './calculate_db.js'
import {
    get_type_podacha_water,
    get_productivity_hour
} from './calculateFunction.js'
import { CSSTransition } from 'react-transition-group'
import { Context } from '../../index.js'
import { translateArray } from '../helpers/common'

const CalculateData = observer(() => {

    const { app } = useContext(Context)

    const [showMist, setShowMist] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const [opros,setOpros] = useState(opros_default)
    const {opros_id} = useParams()
    const [opros_is_loading,setOprosIsLoading] = useState(true)
    const [loading,setLoading] = useState(false)
    const [fullOprosData,setfullOprosData] = useState({
        product_category: [],
        package_list: []
    })

    const [titlePage,setTitlePage] = useState('')
    const [BreadCrumbArray,setBreadCrumbArray] = useState([])

    const history = useHistory()

    React.useEffect(() => {
        translateArray(type_autovlave_list,app)
        translateArray(type_podacha_water_list,app)
        translateArray(type_ukladka_list,app)
        translateArray(complect_basket_list,app)
        translateArray(complect_telejka_list,app)
    },[app.lang])

    React.useEffect(async () => {
        const fullOprosData = await getFullOprosData()
        setfullOprosData(fullOprosData)
    },[])

    React.useEffect(async () => {
        if (opros_id && fullOprosData.product_category.length > 0) {
            let opros_data = await getOpros(opros_id)
            opros_data.time_circle = parseFloat(opros_data.time_zagruzka) + parseFloat(opros_data.time_ohlajdenia) + parseFloat(opros_data.time_viderjka) + parseFloat(opros_data.time_nagrev)
            opros_data.is_normal_type_ukladka = check_is_normal_type_ukladka(opros_data)
            opros_data.is_normal_type_podacha_water_id = check_is_normal_type_podacha_water_id(opros_data)
            setOpros(opros_data)
            setTitlePage(app.translate['start_data'])
            setBreadCrumbArray([
                {
                    link: '/calculate-list',
                    name: app.translate['calculate_list_title'],
                    icon: 'bx bx-home-circle',
                },
                {
                    link: '/calculate-data/'+opros_id,
                    name: app.translate['start_data'],
                    icon: 'bx bx-list-check',
                }
            ])
        } else {
            setTitlePage(app.translate['calculate_data_title'])
            setBreadCrumbArray([
                {
                    link: '/calculate-list',
                    name: app.translate['calculate_list_title'],
                    icon: 'bx bx-home-circle',
                },
                {
                    link: '/calculate-data',
                    name: app.translate['calculate_data_title'],
                    icon: 'bx bx-list-minus',
                }
            ])
        }
    },[fullOprosData])


    

    const saveForm = async (param) => {

        // Валидация формы
        // проверка есть ли is-invalid
        if (validForm(opros)) {

            // Отправка формы на сервер
            setLoading(true)
            let data = await saveOpros(opros)

            // Добавляем opros_id в объект, чтобы в дальнейшем не новый создавать
            // а сохранять в старый
            let o_id = data.opros_id
            if (opros_is_loading == false || !opros_id) {
                    
                setOpros({
                    ...opros,
                    opros_id: o_id
                })

            }

            setIsSaved(true)
            setTimeout(() => {
                setIsSaved(false)
                setLoading(false)
            }, 2000)

            // Если просто сохранить, ничего не делаем
            // Если сохранить и продолжить, переходим на следуюющую страницу
            if (param === 'just_save') {

            } else if (param === 'goToIshodData') {
                history.push('/calculate-data/'+o_id)
            } else if (param === 'goToItog') {
                history.push('/calculate-itog/'+o_id)
            }
            
            setShowMist(false)

        } else {

            setShowMist(true)

        }

    }

    const changeOption = (e) => {

        let new_val

        if (opros[e.target.name] === 1) {
            new_val = 0
        } else {
            new_val = 1
        }
        setOpros({
            ...opros,
            [e.target.name]: new_val,
        })
    }

    const check_is_normal_type_ukladka = (op) => {
        let tuk = 0
        if (String(op.type_podacha_water_id) === "1") { // водный каскад
            tuk = 1
        } else { // водный душ 
            tuk = 2
        }

        if (String(tuk) === String(op.type_ukladka)) return 1
        return 0
    }


    const check_is_normal_type_podacha_water_id = (op) => {
        let tpw = get_type_podacha_water(op)
        if (String(tpw) === String(op.type_podacha_water_id)) return 1
        return 0
    }

    const onChangeOpros = (e) => {

        // Действия если изменена упаковка (меняем типо подачи воды)
        if (e.target.name === 'package_id') {
            opros[e.target.name] = e.target.value
            opros.type_podacha_water_id = get_type_podacha_water(opros)
            if (String(opros.type_podacha_water_id) === "1") {
                opros.height_between = '4'
            } else {
                opros.height_between = '14'
            }
        }

        // Если водный душ – поддоны (без корзины). В характеристиках есть цена поддона. 
        if (e.target.name === 'type_podacha_water_id' || e.target.name === 'package_id') {
            opros[e.target.name] = e.target.value
            if (e.target.name != 'type_podacha_water_id') {
                opros.type_podacha_water_id = get_type_podacha_water(opros)
            } else {
                opros.type_podacha_water_id = e.target.value
            }
            if (String(opros.type_podacha_water_id) === "1") { // водный каскад
                opros.type_ukladka = '1'
            } else { // водный душ 
                opros.type_ukladka = '2'
            }
        }

        if (e.target.name === 'time_zagruzka' || e.target.name === 'time_ohlajdenia' || e.target.name === 'time_viderjka' || e.target.name === 'time_nagrev') {
            opros[e.target.name] = e.target.value
            opros.time_circle = parseFloat(opros.time_zagruzka) + parseFloat(opros.time_ohlajdenia) + parseFloat(opros.time_viderjka) + parseFloat(opros.time_nagrev)        
        }

    
        if (e.target.name === 'productivity' || e.target.name === 'productivity_unit' || e.target.name === 'col_work_hour') {
            opros[e.target.name] = e.target.value
            opros.productivity_hour = get_productivity_hour(opros)
        }

        if (e.target.name === 'type_ukladka') {
            opros[e.target.name] = e.target.value
        }

        if (e.target.name === 'weight_netto' || e.target.name === 'weight_upak') {
            opros[e.target.name] = e.target.value
            let weight_upak = 0
            if (opros.weight_upak !== '') {
                weight_upak = parseFloat(opros.weight_upak)
            }
            let weight_netto = 0
            if (opros.weight_netto !== '') {
                weight_netto = parseFloat(opros.weight_netto)
            }
            let weight_brutto = weight_upak + weight_netto
            opros.weight_brutto = weight_brutto
        }

        // Приведение типов для SELECT
        if (
            e.target.name === 'type_podacha_water_id'
        ) {
            opros[e.target.name] = e.target.value
            e.target.value = parseInt(e.target.value)
        }



        let is_normal_type_ukladka = check_is_normal_type_ukladka(opros)
        let is_normal_type_podacha_water_id = check_is_normal_type_podacha_water_id(opros)

        console.log(is_normal_type_ukladka + " - is_normal_type_ukladka")
        console.log(is_normal_type_podacha_water_id + " - is_normal_type_podacha_water_id")

        setOpros({
            ...opros,
            [e.target.name]: e.target.value,
            type_podacha_water_id: opros.type_podacha_water_id,
            productivity_hour: opros.productivity_hour,
            type_ukladka: opros.type_ukladka,
            weight_brutto: opros.weight_brutto,
            time_circle: opros.time_circle,
            is_normal_type_ukladka: is_normal_type_ukladka,
            is_normal_type_podacha_water_id: is_normal_type_podacha_water_id,
        })

        if (showMist == true && validForm(opros)) setShowMist(false)
    }

    const clearForm = () => {
        setOpros(opros_default)
    }
    
    

    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={titlePage} data={BreadCrumbArray}/>
                        
                        <div className="row">

                            <div className="col-12 col-lg-6" id="oprosData">

                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <h5 className="mb-0">{ app.translate['opros_table_head_info'] }</h5>
                                        </div>
                                        <hr/>
                                        <div className="block-opros">
                        
                                            <div className="form_row">
                                                <div className="form_col"><input onChange={onChangeOpros} value={opros.company_name} name="company_name" placeholder={ app.translate['company_name'] } type="text" className="form-control form-control-sm"/></div>
                                            </div>

                                            <div className="form_row mt-2">
                                                <div className="form_col"><input onChange={onChangeOpros} value={opros.fio} name="fio" placeholder={ app.translate['fio'] } type="text" className="form-control form-control-sm"/></div>
                                            </div>

                                            <div className="form_row mt-2">
                                                <div className="form_col"><input onChange={onChangeOpros} value={opros.email} name="email" placeholder={ app.translate['email'] } type="text" className="form-control form-control-sm"/></div>
                                            </div>

                                            <div className="form_row mt-2">
                                                <div className="form_col"><input onChange={onChangeOpros} value={opros.phone} name="phone" placeholder={ app.translate['phone'] } type="text" className="form-control form-control-sm"/></div>
                                            </div>
                                        </div>

                                    </div> 
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <h5 className="mb-0">{ app.translate['opros_table_head_product'] }</h5>
                                        </div>
                                        <hr/>
                                        <div className="block-opros">
                                                
                                                
                                            <div className="row pt-1 pb-1 bg-thead-opros">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['table_name'] }</div>
                                                        <div className="col-3 text-center">{ app.translate['table_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">{ app.translate['table_value'] }</div>
                                            </div>


                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['product_category_id'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.product_category_id} name="product_category_id" className="form-control form-control-sm">
                                                        <option value="0">{ app.translate['choose_variant'] }</option>
                                                        {fullOprosData.product_category.map((pc) => {
                                                            return (
                                                                <option key={pc.product_category_id} value={pc.product_category_id}>{pc['name_' + app.lang]}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['product'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.product_id} name="product_id" className="form-control form-control-sm">
                                                        <option value="0">{ app.translate['choose_variant_from_list'] }</option>
                                                        {fullOprosData.product_category.map((pr) => {
                                                            if (pr.product_category_id == opros.product_category_id) {
                                                                return (
                                                                    pr.product_list.map((pr)=>{
                                                                        return (
                                                                            <option key={pr.product_id} value={pr.product_id}>{pr['name_' + app.lang]}</option>
                                                                        )
                                                                    })
                                                                )
                                                            }
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['package_id'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.package_id} name="package_id" className="form-control form-control-sm">
                                                        <option value="0">{ app.translate['choose_variant_from_list'] }</option>
                                                        {fullOprosData.package_list.map((pkg)=>{
                                                            return (
                                                                <option key={pkg.package_id} value={pkg.package_id}>{pkg['name_' + app.lang]}</option>
                                                            )
                                                        })}
                                                        <option value="-1" >{ app.translate['other'] }</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['d_w_h'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3 text-center">{ app.translate['d_w_h_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="d-flex">
                                                        <div className=""><input onChange={onChangeOpros} type="number" step="any" value={opros.razmer_upak_length} name="razmer_upak_length" placeholder="" className="form-control form-control-sm"/></div>
                                                        <div className="" style={{width: 20, textAlign: 'center'}}>x</div>
                                                        <div className=""><input onChange={onChangeOpros} type="number" step="any" value={opros.razmer_upak_width} name="razmer_upak_width" placeholder="" className="form-control form-control-sm"/></div>
                                                        <div className="" style={{width: 20, textAlign: 'center'}}>x</div>
                                                        <div className=""><input onChange={onChangeOpros} type="number" step="any" value={opros.razmer_upak_height} name="razmer_upak_height" placeholder="" className="form-control form-control-sm"/></div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <h5 className="mb-0">{ app.translate['opros_table_head_time'] }</h5>
                                        </div>
                                        <hr/>
                                        <div className="block-opros">
                                                
                                                
                                            <div className="row pt-1 pb-1 bg-thead-opros">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['table_name'] }</div>
                                                        <div className="col-3 text-center">{ app.translate['table_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">{ app.translate['table_value'] }</div>
                                            </div>


                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto"><div>{ app.translate['time_nagrev'] }</div></div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['time_nagrev_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.time_nagrev} name="time_nagrev" placeholder="" type="number" step="1" className="form-control form-control-sm"/></div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto"><div>{ app.translate['time_viderjka'] }</div></div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['time_viderjka_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.time_viderjka} name="time_viderjka" placeholder="" type="number" step="1" className="form-control form-control-sm"/></div>
                                            </div>


                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto"><div>{ app.translate['time_ohlajdenia'] }</div></div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['time_ohlajdenia_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.time_ohlajdenia} name="time_ohlajdenia" placeholder="" type="number" step="1" className="form-control form-control-sm"/></div>
                                            </div>
                                            
                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto"><div>{ app.translate['time_zagruzka'] }</div></div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['time_zagruzka_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.time_zagruzka} name="time_zagruzka" placeholder="" type="number" step="1" className="form-control form-control-sm"/></div>
                                            </div>
                                                        

                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto"><div>{ app.translate['time_circle'] }</div></div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['time_circle_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input disabled value={opros.time_circle} type="number" step="1" className="form-control form-control-sm"/></div>
                                            </div>


                                        </div>
                                    </div>
                                </div>



                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <h5 className="mb-0">{ app.translate['opros_table_head_main_process'] }</h5>
                                        </div>
                                        <hr/>
                                        <div className="block-opros">
                                            

                                            <div className="row pt-1 pb-1 form_row bg-thead-opros">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['table_name'] }</div>
                                                        <div className="col-3 text-center">{ app.translate['table_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">{ app.translate['table_value'] }</div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto">{ app.translate['productivity'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3 my-auto text-center">
                                                            <select onChange={onChangeOpros} value={opros.productivity_unit} name="productivity_unit" className="form-control form-control-sm" style={{textAlign:'center'}}>
                                                                <option value="шт / мин">{ app.translate['productivity_unit_count_min'] }</option>
                                                                <option value="шт / час" >{ app.translate['productivity_unit_count_hour'] }</option>
                                                                <option value="шт / загрузка" >{ app.translate['productivity_unit_count_zagruzka'] }</option>
                                                                <option value="шт / смена" >{ app.translate['productivity_unit_count_smena'] }</option>
                                                                <option value="шт / сутки" >{ app.translate['productivity_unit_count_day'] }</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto">
                                                    <input onChange={onChangeOpros} value={opros.productivity} name="productivity" type="number" step="any" className="form-control form-control-sm"/>
                                                </div>
                                            </div>

                                            
                                            
                                            {(opros.productivity_unit === 'шт / смена' || opros.productivity_unit === 'шт / сутки') && 

                                            <div className="row pt-1 pb-1 form_row mt-2" id="block_col_work_hour">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto">{ app.translate['productivity_unit_col_hour_at'] } {(opros.productivity_unit === 'шт / смена' ? app.translate['productivity_unit_col_hour_at_smena'] : app.translate['productivity_unit_col_hour_at_day'])} <span className="red pl-2">*</span></div>
                                                        <div className="col-3 my-auto text-center">ч</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.col_work_hour} name="col_work_hour" id="col_work_hour" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>
                                            }


                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto"><div>{ app.translate['max_time_suspend_before_ster'] }</div></div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['max_time_suspend_before_ster_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto">
                                                    <input onChange={onChangeOpros} type="number" step="any" value={opros.ogranichenie_on_time} name="ogranichenie_on_time" className="form-control form-control-sm"/>
                                                </div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto">{ app.translate['temp_product_before_steril'] }</div>
                                                        <div className="col-3 my-auto text-center">°С</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.temp_product_before_steril} name="temp_product_before_steril" placeholder="" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>


                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto">{ app.translate['temp_product_sterilizacia'] }</div>
                                                        <div className="col-3 my-auto text-center">°С</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.temp_product_sterilizacia} name="temp_product_sterilizacia" placeholder="" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto">{ app.translate['temp_on_out_autoclaves'] }</div>
                                                        <div className="col-3 my-auto text-center">°С</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.temp_on_out_autoclaves} name="temp_on_out_autoclaves" placeholder="" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>


                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto">{ app.translate['diametr_autoclaves'] }</div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['diametr_autoclaves_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.diametr_autoclaves} name="diametr_autoclaves" placeholder="" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto">{ app.translate['razmer_pod_autoclaves'] }</div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['razmer_pod_autoclaves_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto">
                                                    <div className="d-flex">
                                                        <div><input onChange={onChangeOpros} type="number" step="any" value={opros.razmer_pod_autoclaves_length} name="razmer_pod_autoclaves_length" placeholder="" className="form-control form-control-sm"/></div>
                                                        <div style={{width: 20, textAlign: 'center'}}>x</div>
                                                        <div><input onChange={onChangeOpros} type="number" step="any" value={opros.razmer_pod_autoclaves_width} name="razmer_pod_autoclaves_width" placeholder="" className="form-control form-control-sm"/></div>
                                                        <div style={{width: 20, textAlign: 'center'}}>x</div>
                                                        <div><input onChange={onChangeOpros} type="number" step="any" value={opros.razmer_pod_autoclaves_height} name="razmer_pod_autoclaves_height" placeholder="" className="form-control form-control-sm"/></div>
                                                    </div>  
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <CSSTransition key="key_mist_1" in={showMist} className='fadeInOut' timeout={300} unmountOnExit>
                                    <div>
                                        <div className="alert border-0 border-start border-5 border-danger alert-dismissible fade show py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="font-35 text-danger"><i className="bx bxs-message-square-x"></i>
                                                </div>
                                                <div className="ms-3">
                                                    <h6 className="mb-0 text-danger">{ app.translate['text_mistake'] }</h6>
                                                    <div>{ app.translate['text_mistake_opros'] }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>

                                <CSSTransition key="key_mist_2" in={isSaved} className='fadeInOut' timeout={300} unmountOnExit>
                                    <div>
                                        <div className="alert border-0 border-start border-5 border-success alert-dismissible fade show py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="font-35 text-success"><i className="bx bxs-check-circle"></i>
                                                </div>
                                                <div className="ms-3">
                                                    <h6 className="mb-0 text-success">{ app.translate['title_success'] }</h6>
                                                    <div>{ app.translate['text_opros_success'] }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>


                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">

                                            <div className="my-auto">
                                                {!opros_id &&
                                                    <button onClick={clearForm} type="button" className="btn btn-sm btn-outline-dark  px-5"><i className="bx bx-trash mr-1"></i>{ app.translate['clear_form'] }</button>
                                                }
                                            </div>

                                            <div className="my-auto">
                                                
                                                {opros_id &&
                                                <span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button onClick={() => saveForm('just_save')} type="button" className="btn btn-sm btn-success px-5"><i className="bx bx-save mr-1"></i>{ app.translate['save'] }</button>
                                                </span>
                                                }
                                            
                                                {!opros_id &&
                                                <span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button onClick={() => saveForm('goToIshodData')} type="button" className="btn btn-sm btn-success px-5"><i className="bx bx-save mr-1"></i>{ app.translate['save_and_go'] }</button>
                                                </span>
                                                }

                                       
                                            
                                            </div>  

                                        </div>                
                                    </div>
                                </div>

                            </div>

                            {opros_id &&

                            <div className="col-12 col-lg-6" id="ishodData">
                                
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <h5 className="mb-0">{ app.translate['main_data_for_calculate'] }</h5>
                                        </div>
                                        <hr/>
                                        <div className="block-opros">
                                            
                                                
                                            <div className="row pt-1 pb-1 bg-thead-opros">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['table_name'] }</div>
                                                        <div className="col-3 text-center">{ app.translate['table_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">{ app.translate['table_value'] }</div>
                                            </div>


                                            
                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['type_autoclave'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.type_autovlave_id} name="type_autovlave_id" className="form-control form-control-sm">
                                                        {type_autovlave_list.map((ta) => {
                                                            return (
                                                                <option key={ta.type_autovlave_id} value={ta.type_autovlave_id}>{ta.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['col_door'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.col_door} name="col_door" className="form-control form-control-sm">
                                                        {col_door_list.map((dt) => {
                                                            return (
                                                                <option key={dt.col_door} value={dt.col_door}>{dt.col_door}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['type_podacha_water_id'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.type_podacha_water_id} name="type_podacha_water_id" className="form-control form-control-sm" style={(opros.is_normal_type_podacha_water_id === 1 ? {backgroundColor: ''} : {backgroundColor: 'yellow'})}>
                                                        <option value="0">{ app.translate['choose_variant'] }</option>
                                                        {type_podacha_water_list.map((type_podacha_water) => {
                                                            return (
                                                                <option key={type_podacha_water.type_podacha_water_id} value={type_podacha_water.type_podacha_water_id}>{type_podacha_water.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['type_ukladka'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.type_ukladka} name="type_ukladka" className="form-control form-control-sm" style={(opros.is_normal_type_ukladka === 1 ? {backgroundColor: ''} : {backgroundColor: 'yellow'})}>
                                                        <option value="0">{ app.translate['choose_variant'] }</option>
                                                        {type_ukladka_list.map((one) => {
                                                            return (
                                                                <option key={one.type_ukladka_id} value={one.type_ukladka_id}>{one.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>


                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['height_between'] }</div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.height_between} name="height_between" placeholder="" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>



                                            
                                            

                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto"><div>{ app.translate['productivity_hour'] }</div></div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['hour_small'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.productivity_hour} name="productivity_hour" placeholder="" disabled type="number" step="1" className="form-control form-control-sm"/></div>
                                            </div>

                                            
                                            
                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['complect_basket_id'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.complect_basket_id} name="complect_basket_id" className="form-control form-control-sm">
                                                        <option value="0">{ app.translate['choose_variant'] }</option>
                                                        {complect_basket_list.map((one) => {
                                                            return (
                                                                <option key={one.complect_basket_id} value={one.complect_basket_id}>{one.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            {(String(opros.complect_basket_id) === '4') &&

                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['complect_basket_id'] }</div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.user_col_basket} name="user_col_basket" placeholder="" type="number" step="1" className="form-control form-control-sm"/></div>
                                            </div>

                                            }

                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['complect_telejka_id'] } <span className="red pl-2">*</span></div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <select onChange={onChangeOpros} value={opros.complect_telejka_id} name="complect_telejka_id" className="form-control form-control-sm">
                                                        <option value="0">{ app.translate['choose_variant'] }</option>
                                                        {complect_telejka_list.map((one) => {
                                                            return (
                                                                <option key={one.complect_telejka_id} value={one.complect_telejka_id}>{one.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            {(String(opros.complect_telejka_id) === '4') &&

                                            <div className="row pt-1 pb-1 form_row mt-3">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['complect_telejka_id'] }</div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.user_col_telejka} name="user_col_telejka" placeholder="" type="number" step="1" className="form-control form-control-sm"/></div>
                                            </div>

                                            }



                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <h5 className="mb-0">Вес</h5>
                                        </div>
                                        <hr/>
                                        <div className="block-opros">
                                            
                                            <div className="row pt-1 pb-1 form_row bg-thead-opros">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['table_name'] }</div>
                                                        <div className="col-3 text-center">{ app.translate['table_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">{ app.translate['table_value'] }</div>
                                            </div>

                                            <div className="row form_row mt-2">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['weight_netto'] }</div>
                                                        <div className="col-3 text-center">{ app.translate['weight_netto_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6"><input onChange={onChangeOpros} value={opros.weight_netto} name="weight_netto" placeholder="" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>

                                            <div className="row pt-1 pb-1 form_row mt-2">
                                                <div className="col-6 my-auto">
                                                    <div className="row">
                                                        <div className="col-9 my-auto"><div>{ app.translate['weight_upak'] }</div></div>
                                                        <div className="col-3 my-auto text-center">{ app.translate['weight_upak_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 my-auto"><input onChange={onChangeOpros} value={opros.weight_upak} name="weight_upak" placeholder="" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>

                                            <div className="row form_row mt-2">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-9">{ app.translate['weight_brutto'] }</div>
                                                        <div className="col-3 text-center">{ app.translate['weight_brutto_unit'] }</div>
                                                    </div>
                                                </div>
                                                <div className="col-6"><input disabled onChange={onChangeOpros} value={opros.weight_brutto} name="weight_brutto" placeholder="" type="number" step="any" className="form-control form-control-sm"/></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <h5 className="mb-0">{ app.translate['option'] }</h5>
                                        </div>
                                        <hr/>
                                        <div className="block-opros">
                                            {(opros.type_ukladka === '1' || opros.type_ukladka === 1) && 
                                                <div>
                                                    <div className="row pt-0 pb-0 form_row mt-2">
                                                        <div className="col-12">
                                                            <input type="checkbox" name="option_basket" onChange={(e)=>changeOption(e)} checked={opros.option_basket} id="option_basket"/> <label for="option_basket">{ app.translate['option_basket'] }</label>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-0 pb-0 form_row mt-2">
                                                        <div className="col-12">
                                                            <input type="checkbox" name="option_prokl" onChange={(e)=>changeOption(e)} checked={opros.option_prokl} id="option_prokl"/> <label for="option_prokl">{ app.translate['option_prokl'] }</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {(opros.type_ukladka === '2' || opros.type_ukladka === 2) && 
                                                <div className="row pt-0 pb-0 form_row mt-2">
                                                    <div className="col-12">
                                                        <input type="checkbox" name="option_poddon" onChange={(e)=>changeOption(e)} checked={opros.option_poddon} id="option_poddon"/> <label for="option_poddon">{ app.translate['option_poddon'] }</label>
                                                    </div>
                                                </div>
                                            }

                                            <div className="row pt-0 pb-0 form_row mt-2">
                                                <div className="col-12">
                                                    <input type="checkbox" name="option_telejka" onChange={(e)=>changeOption(e)} checked={opros.option_telejka} id="option_telejka"/> <label for="option_telejka">{ app.translate['option_telejka'] }</label>
                                                </div>
                                            </div>

                                            <div className="row pt-0 pb-0 form_row mt-2">
                                                <div className="col-12">
                                                    <input type="checkbox" name="option_pnr" onChange={(e)=>changeOption(e)} checked={opros.option_pnr} id="option_pnr"/> <label for="option_pnr">{ app.translate['pnr'] }</label>
                                                </div>
                                            </div>
                                            
                                            <div className="row pt-0 pb-0 form_row mt-2">
                                                <div className="col-12">
                                                    <input type="checkbox" name="option_door" onChange={(e)=>changeOption(e)} checked={opros.option_door} id="option_door"/> <label for="option_door">{ app.translate['vertical_door'] }</label>
                                                </div>
                                            </div>

                                            <div className="row pt-0 pb-0 form_row mt-2">
                                                <div className="col-12">
                                                    <input type="checkbox" name="option_sdoor" onChange={(e)=>changeOption(e)} checked={opros.option_sdoor} id="option_sdoor"/> <label for="option_sdoor">{ app.translate['second_door'] }</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

 
                                              
                                <CSSTransition key="key_mist_3" in={showMist} className='fadeInOut' timeout={300} unmountOnExit>
                                    <div>
                                        <div className="alert border-0 border-start border-5 border-danger alert-dismissible fade show py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="font-35 text-danger"><i className="bx bxs-message-square-x"></i>
                                                </div>
                                                <div className="ms-3">
                                                    <h6 className="mb-0 text-danger">{ app.translate['text_mistake'] }</h6>
                                                    <div>{ app.translate['text_mistake_opros'] }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>

                                <CSSTransition key="key_mist_4" in={isSaved} className='fadeInOut' timeout={300} unmountOnExit>
                                    <div>
                                        <div className="alert border-0 border-start border-5 border-success alert-dismissible fade show py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="font-35 text-success"><i className="bx bxs-check-circle"></i>
                                                </div>
                                                <div className="ms-3">
                                                    <h6 className="mb-0 text-success">{ app.translate['title_success'] }</h6>
                                                    <div>{ app.translate['text_opros_success'] }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">

                                            <div className="my-auto">
                                                
                                            </div>

                                            <div className="my-auto">

                                                <span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button onClick={() => saveForm('just_save')} type="button" className="btn btn-sm btn-success px-5"><i className="bx bx-save mr-1"></i>{ app.translate['save'] }</button>
                                                </span>
 
                                                <span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button onClick={() => saveForm('goToItog')} type="button" className="btn btn-sm btn-success px-5"><i className="bx bx-save mr-1"></i>{ app.translate['save_and_go_result'] }</button>
                                                </span>
                                               
                                            </div>  

                                        </div>                
                                    </div>
                                </div>


                            </div>
                            }

                        </div>

                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
})

export default CalculateData