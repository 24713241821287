import { useEffect, useState, useContext } from 'react'
import { setPriceAutoclave, getPriceAutoclave, getComment } from '../../http/catalogAPI.js'
import { layout, layout_type, type_d, col_basket_array, getArrData } from '../../utils/catalog.js'
import LogModal from './view/LogModal.js'
import LogCommentModal from './view/LogCommentModal.js'
import InputNumberPretty from '../ui/InputNumberPretty.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'
import { translateArrayLayout, translateArrayLayoutType } from '../helpers/common'

const AutoclavePrice = observer( () => {

    const { app } = useContext(Context)

    const [arrPrice,setArrayPrice] = useState([])
    const [price_log_id, setPriceLog] = useState(0)
    const [showLogModal, setShowLogModal] = useState(false)
    const [valueKey, setValueKey] = useState('')

    const [price_log_comment, setPriceLogComment] = useState([])
    const [showLogCommentModal, setShowLogCommentModal] = useState(false)
    
    const changePriceAutoclave = (e, key_ob) => {
        let val = e.target.value.replace(/\s/g, '').replace(/,/g, '.')
        setArrayPrice({...arrPrice,[key_ob]: val}) 
        setValueKey(key_ob)
    }

    const changeKeyOb = (value, key_ob) => {
        setArrayPrice({...arrPrice,[key_ob]: value}) 
    }
    
    const blurPriceAutoclave = async (e, key_ob) => {

        let val = e.target.value.replace(/\s/g, '').replace(/,/g, '.')
        const res = await setPriceAutoclave(key_ob, val)
        console.log(res)

        if ('price_log_id' in res) {
            setPriceLog(res.price_log_id)
            setShowLogModal(true)
        }

    }

    const changeShowLogModal = async (val) => {
        setShowLogModal(val)
    }

    const changeShowLogCommentModal = async (val) => {
        setShowLogCommentModal(val)
    }

    const loadComment = async (key_ob) => {
        const res = await getComment(key_ob,'autoclaves')
        console.log(res)
        setPriceLogComment(res)
        setShowLogCommentModal(true)
    }

    
    useEffect(() => {
        translateArrayLayout(layout,app)
        translateArrayLayoutType(layout_type,app)
    },[app.lang])

    useEffect( async () => {
        const arr_res = await getPriceAutoclave()
        let arr = getArrData(layout, layout_type, type_d, col_basket_array, arr_res)
        setArrayPrice(arr)
    },[])


    
    let content = []

    // Заголовок таблицы
    let row = (
        <tr style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>
            <td></td>
            <td></td>
            <td></td>
            {
                col_basket_array.map(col_basket=>{
                    return (
                        <td colspan="2">
                            {col_basket} { app.translate['col_basket_unit'] }
                        </td>
                    )
                }) 
            }
        </tr>
    )
    
    content.push(row)

    let layout_control = 0
    let type_id_control = 0
    for (let layout_id in layout) {
        for (let type_id in layout_type[layout_id]) {
            for (let d_key in type_d[type_id]) {
                let d = type_d[type_id][d_key]

                let layout_colspan = 1
                if (layout_control !== layout_id) {
                    layout_colspan = 0
                    for (let type_id_temp in layout_type[layout_id]) {
                        for (let d_key_temp in type_d[type_id_temp]) {
                            layout_colspan++
                        }
                    }
                }
                layout_control = layout_id

                let type_colspan = 1
                if (type_id_control !== type_id) {
                    type_colspan = Object.keys(type_d[type_id]).length
                }
                type_id_control = type_id
                

                let row = (
                    <tr>
                        {(layout_colspan > 1) && 
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}} rowSpan={layout_colspan}><div className="rotate90">{layout[layout_id]['name']}</div></td>
                        }
                        {(type_colspan > 1) && 
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}} rowSpan={type_colspan}><div className="rotate90">{layout_type[layout_id][type_id]['name']}</div></td>
                        }
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>{d}</td>
                        {
                            col_basket_array.map(col_basket=>{

                                let key_ob = layout_id + "_" + type_id + "_" + d + "_" + col_basket

                                return (
                                    <>
                                        <td style={{borderRight: 0}}>
                                            <InputNumberPretty
                                                className="form-control form-control-sm" 
                                                value={arrPrice[key_ob] ? arrPrice[key_ob] : 0} 
                                                onBlur={blurPriceAutoclave} 
                                                onChange={changePriceAutoclave}
                                                key_ob={key_ob}
                                            />
                                        </td>
                                        <td style={{borderLeft: 0, textAlign: 'center'}}>
                                            {arrPrice[key_ob] !== 0  && 
                                                <i onClick={()=>loadComment(key_ob)} style={{cursor: 'pointer'}} className='bx bx-info-circle'></i>
                                            }
                                        </td>
                                    </>
                                )
                            }) 
                        }
                    </tr>
                )

                content.push(row)

            }
        }
    }

    

    return (
        <div style={{overflow:'auto'}}>
            <div className="card">
                
                <table className="tableAutoclavePrice">
                    <tbody>
                        <tr style={{fontWeight: 600, backgroundColor: 'rgb(240,240,240'}}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan={col_basket_array.length * 2}>{ app.translate['col_basket'] }</td>
                        </tr>
                        {content.map((row)=>{
                            return row
                        })}
                    </tbody>
                </table>
            
            </div>

            <LogModal 
                changeKeyOb={changeKeyOb} 
                valueKey={valueKey} 
                showLogModal={showLogModal} 
                changeShowLogModal={changeShowLogModal}
                price_log_id={price_log_id}
            ></LogModal>

            <LogCommentModal 
                showLogCommentModal={showLogCommentModal} 
                changeShowLogCommentModal={changeShowLogCommentModal} 
                price_log_comment={price_log_comment}
            ></LogCommentModal>

        </div>
    )

} )

export default AutoclavePrice