import { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'
import { 
    calculateItog, 
    calculateItogPart 
} from '../../utils/calculate'

const CalculatePriceTableOne = observer( (params) => {

    const { app } = useContext(Context)

    const sale = (price, pole = 'commont') => {

        if (params.for === 'client') return price
        
        price = parseFloat(price)
        price = price * 0.8
        price = Math.round(price)
        return price

    }

    const [ itog, setItog ] = useState(0)
    const [ opros, setOpros ] = useState({})
    const [ course_euro, setCourseEuro ] = useState(0)

    useEffect(() => {
        let course_euro = app.system.filter( i=> i.system_id == 1)[0].value
        setCourseEuro(course_euro)
        setOpros(params.opros)
        setItog(calculateItog(params.opros,course_euro))
    },[params])
    
    
    return (

        <table className="calculate-price-table">
            <tr>
                <td>{ app.translate['finance_autoclave'] }</td>
                <td>{ params.itog.col_autoclave ? params.itog.col_autoclave : '..' }</td>
                <td>{ params.itog.col_autoclave ? sale(params.itog.price_one_autoclave) + " €" :  '..' }</td>
            </tr>

            { params.itog.total_col_basket > 0 && 
                <tr>
                    <td>Корзины{ app.translate['kp_included_basket'] }</td>
                    <td>{ params.itog.col_autoclave ? params.itog.total_col_basket : '..' }</td>
                    <td>{ params.itog.col_autoclave ? sale(params.itog.total_price_basket) + " €" : '..' }</td>
                </tr>
            }

            { params.itog.total_col_telejka > 0 && 
                <tr>
                    <td>Тележки{ app.translate['kp_included_carriages'] }</td>
                    <td>{ params.itog.col_autoclave ? params.itog.total_col_telejka : '..' }</td>
                    <td>{ params.itog.col_autoclave ? sale(params.itog.total_price_telejka) + " €" : '..' }</td>
                </tr>
            }

            { params.itog.col_prokl_poddon > 0 && 
                <tr>
                    <td>{ params.itog.is_poddon === 1 && <>{ app.translate['finance_trays'] }</> } { params.itog.is_prokl === 1 && <>{ app.translate['kp_included_dividers'] }</> }</td>
                    <td>{ params.itog.col_autoclave ? params.itog.col_prokl_poddon : '..' }</td>
                    <td>{ params.itog.col_autoclave ? sale(params.itog.total_price_prokl_poddon) + " €" : '..' }</td>
                </tr>
            }

            { params.itog.total_col_door > 0 && 
                <tr>
                    <td>{ app.translate['vertical_door'] }</td>
                    <td>{ params.itog.col_autoclave ? params.itog.total_col_door : '..' }</td>
                    <td>{ params.itog.col_autoclave ? sale(params.itog.total_price_door) + " €" : '..' }</td>
                </tr>
            }

            { params.itog.total_col_sdoor > 0 && 
                <tr>
                    <td>{ app.translate['second_door'] }</td>
                    <td>{ params.itog.col_autoclave ? params.itog.total_col_sdoor : '..' }</td>
                    <td>{ params.itog.col_autoclave ? sale(params.itog.total_price_sdoor) + " €" : '..' }</td>
                </tr>
            }

            { params.itog.total_col_pnr > 0 && 
                <tr>
                    <td>{ app.translate['pnr'] }</td>
                    <td>{ params.itog.col_autoclave ? params.itog.total_col_pnr : '..' }</td>
                    <td>{ params.itog.col_autoclave ? sale(params.itog.total_price_pnr) + " €" : '..' }</td>
                </tr>
            }

            { params.for === 'client' && opros.delivery === 1 &&
                <tr>
                    <td>{ app.translate['finance_delivery_and_clear'] }</td>
                    <td>1</td>
                    <td>{ itog }</td>
                </tr>
            }

            <tr style={{backgroundColor: 'rgb(220,220,255)'}}>
                <td></td>
                <td style={{fontWeight: '600', fontSize: 14}}>{ app.translate['kp_itog'] }</td>
                <td style={{fontWeight: '600', fontSize: 14}}>{ params.itog.col_autoclave ? sale(params.itog.price_autoclave + itog) + " €" : '..' }</td>
            </tr>
            
        </table>

    )
    
} )

export default CalculatePriceTableOne