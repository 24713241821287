import { useContext, useEffect, useState } from 'react'
import { Context } from '../../index.js'
import { observer } from 'mobx-react'
import { 
    calculateItog, 
    calculateItogPart 
} from '../../utils/calculate'

const CalculatePriceTableId = observer((params) => {

    const { app } = useContext(Context)

    const [ itog, setItog ] = useState(0)
    const [ opros, setOpros ] = useState({})
    const [ course_euro, setCourseEuro ] = useState(0)

    useEffect(() => {
        let course_euro = app.system.filter( i=> i.system_id == 1)[0].value
        setCourseEuro(course_euro)
        setOpros(params.opros)
        setItog(calculateItog(params.opros,course_euro))
    },[params])


    return (
        <>
            { opros.delivery === 1 && 
                <table className="calculate-price-table">
                    <tr>
                        <td>{ app.translate['kp_name_equipment'] }</td>
                        <td>{ app.translate['table_unit'] }.</td>
                        <td>{ app.translate['finance_price'] }</td>
                        <td>{ app.translate['finance_price'] }, €</td>
                    </tr>
                    <tr>
                        <td>{ app.translate['course'] }</td>
                        <td>{ app.translate['finance_price_unit'] }</td>
                        <td>{ course_euro } { app.translate['finance_price_unit'] }</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{ app.translate['delivery_type_lodos_russia'] }</td>
                        <td>€</td>
                        <td>{ opros.delivery_price_1 }</td>
                        <td>{ opros.delivery_price_1 }</td>
                    </tr>
                    <tr>
                        <td>{ app.translate['finance_dt_without_nds'] }</td>
                        <td>{ app.translate['finance_price_unit'] }</td>
                        <td>{ opros.dt_price }</td>
                        <td>{ Math.round(opros.dt_price / course_euro) }</td>
                    </tr>
                    <tr>
                        <td>{ app.translate['finance_tam_oper_without_nds'] }</td>
                        <td>{ app.translate['finance_price_unit'] }</td>
                        <td>{ opros.tam_posh_price }</td>
                        <td>{ Math.round(opros.tam_posh_price / course_euro) }</td>
                    </tr>
                    <tr>
                        <td>{ app.translate['finance_delivery_russia_without_nds'] }</td>
                        <td>{ app.translate['finance_price_unit'] }</td>
                        <td>{ opros.delivery_price_2 }</td>
                        <td>{ Math.round(opros.delivery_price_2 / course_euro) }</td>
                    </tr>
                    <tr>
                        <td>{ app.translate['finance_sertificate'] }</td>
                        <td>{ app.translate['finance_price_unit'] }</td>
                        <td>{ opros.sertif_price }</td>
                        <td>{ Math.round(opros.sertif_price / course_euro) }</td>
                    </tr>
                    <tr>
                        <td>{ app.translate['finance_staff'] }</td>
                        <td>%</td>
                        <td>{ opros.kurs_pr }</td>
                        <td>{ Math.round( calculateItogPart(opros, course_euro) * opros.kurs_pr / 100 ) }</td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{ app.translate['finance_price_itog_without_nds'] }:</td>
                        <td>{ itog } €</td>
                    </tr>
                </table>
            }
        </>
    )
})

export default CalculatePriceTableId