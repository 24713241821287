import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import HomeBar from '../common/HomeBar.js'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { CALCULATE_LIST_ROUTER, CALCULATE_OPROS_ROUTER } from '../../utils/consts.js'
import { getOprosList, deleteOpros, copyOpros } from '../../http/oprosAPI.js'
import { CSSTransition } from 'react-transition-group'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'


const CalculateList = observer( () => {

    const {app} = useContext(Context)

    const [opros_list, setOprosList] = useState([])
    const [opros_loading, setOprosLoading] = useState(true)
    const [count,setCount] = useState(0)

    useEffect(async ()=>{
        setOprosLoading(true)
        let data = await getOprosList()
        setCount(data.count)
        setOprosList(data.rows)
        setOprosLoading(false)
    },[])

    const copy_opros = async (event, opros_id) => {
        event.preventDefault()
        setOprosLoading(true)
        let res = await copyOpros(opros_id)
        let data = await getOprosList()
        setCount(data.count)
        setOprosList(data.rows)
        setOprosLoading(false)
    }

    const delete_opros = async (event, opros_id) => {
        event.preventDefault()
        setOprosLoading(true)
        let res = await deleteOpros(opros_id)
        let delete_id = res.opros_id
        let opros_list_temp = opros_list.filter((opros) => {
            if (opros.opros_id !== delete_id) {
                return opros
            }
        })
        setOprosList(opros_list_temp)
        setCount(count - 1)
        setOprosLoading(false)

    }

    let BreadCrumbArray = [
        {
            link: '/calculate-list',
            name: app.translate['calculate_list_title'],
            icon: 'bx bx-home-circle',
        }
    ]

    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={ app.translate['calculate_list_title'] } data={BreadCrumbArray}/>

                        
                        <div className="card">
                            <div className="card-body">
                                <div className="d-lg-flex align-items-center mb-4 gap-3">
                                    {false &&
                                    <div className="position-relative">
                                        <input type="text" className="form-control ps-5 radius-30" placeholder="Поиск расчета ..."/> <span className="position-absolute top-50 product-show translate-middle-y"><i className="bx bx-search"></i></span>
                                    </div>
                                    }
                                    <div className="ms-auto"><Link to={CALCULATE_OPROS_ROUTER} className="btn btn-primary radius-30 mt-2 mt-lg-0"><i className="bx bxs-plus-square"></i>{ app.translate['button_create_opros'] }</Link></div>
                                </div>
                                <div className="table-responsive">

                                    {opros_loading && 
                                        <div>
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }

                                    <CSSTransition key="key_mist" in={(opros_list.length > 0)} className='fadeInOut' timeout={300} unmountOnExit>  
                                        <div>
                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>{ app.translate['calculate_list_table_number'] } #</th>
                                                        <th>{ app.translate['calculate_list_table_company_name'] }</th>
                                                        <th>{ app.translate['calculate_list_table_status'] }</th>
                                                        <th>{ app.translate['calculate_list_table_user'] }</th>
                                                        <th>{ app.translate['calculate_list_table_date'] }</th>
                                                        <th>{ app.translate['calculate_list_table_more'] }</th>
                                                        <th>{ app.translate['calculate_list_table_actions'] }</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {opros_list.map( (opros) => {
                                                        return (
                                                            <tr key={opros.opros_id}>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                            <input className="form-check-input me-3" type="checkbox" value="" aria-label="..."/>
                                                                        </div>
                                                                        <div className="ms-2">
                                                                            <h6 className="mb-0 font-14">#{opros.opros_id}</h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{opros.company_name}</td>
                                                                <td><div className="badge rounded-pill text-success bg-light-success p-2 text-uppercase px-3"><i className="bx bxs-circle me-1"></i>{ app.translate['status_on_work'] }</div></td>
                                                                <td>{opros.user_created_name}</td>
                                                                <td>{opros.createdAt.split('T')[0]}</td>
                                                                <td><Link to={'/calculate-data/' + opros.opros_id}><button type="button" className="btn btn-primary btn-sm radius-30 px-4">{ app.translate['calculate_list_table_more_button'] }</button></Link></td>
                                                                <td>
                                                                    <div className="d-flex order-actions">
                                                                        <a onClick={(e) => copy_opros(e,opros.opros_id)} className="" style={{cursor: 'pointer'}}><i className="bx bx-copy"></i></a>
                                                                        <Link to={'/calculate-data/' + opros.opros_id} className="ms-3"><i className="bx bxs-edit"></i></Link>
                                                                        <a onClick={(e) => delete_opros(e,opros.opros_id)} className="ms-3" style={{cursor: 'pointer'}}><i className="bx bxs-trash"></i></a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </CSSTransition>

                                    
                                </div>
                            </div>
                        </div>

                        {false &&
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item">
                                    <a className="page-link" href="javascript:;" aria-label="Previous">	<span aria-hidden="true">«</span></a>
                                </li>
                                <li className="page-item"><a className="page-link" href="javascript:;">1</a></li>
                                <li className="page-item"><a className="page-link" href="javascript:;">2</a></li>
                                <li className="page-item"><a className="page-link" href="javascript:;">3</a></li>
                                <li className="page-item">
                                    <a className="page-link" href="javascript:;" aria-label="Next">	<span aria-hidden="true">»</span></a>
                                </li>
                            </ul>
                        </nav>
                        }
                        

                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
})

export default CalculateList