export const get_type_podacha_water = (opros) => {
    if (Number(opros.package_id) === 1 || Number(opros.package_id) === 2) {
        return 1
    } else {
        return 2
    }
}

export const get_productivity_hour = (opros) => {
    
    let productivity = parseInt(opros.productivity)
    let col_work_hour = parseInt(opros.col_work_hour)
    let productivity_unit = opros.productivity_unit
    let productivity_hour = 0

    if (productivity_unit === 'шт / мин') {
        productivity_hour = productivity * 60
    } else if (productivity_unit === 'шт / час') {
        productivity_hour = productivity
    } else if (productivity_unit === 'шт / загрузка') {
        productivity_hour = productivity
    } else if (productivity_unit === 'шт / смена') {
        productivity_hour = productivity / col_work_hour
    } else if (productivity_unit === 'шт / сутки') {
        productivity_hour = productivity / col_work_hour
    }
    return productivity_hour
}

export const getNameAutoclave = (opros, app) => {

    let name = ''

    if (opros.type_autovlave_id == 1) {
        name = app.translate['autoclave_name_1']
    } else if (opros.type_autovlave_id == 2) {
        name = app.translate['autoclave_name_2']
    } else if (opros.type_autovlave_id == 3) {
        name = app.translate['autoclave_name_3']
    }
    
    return name

}

export const getArrayDiametr = (opros,type_d) => {

    let arr = {}

    if (opros.type_autovlave_id == 1) { // Горизонтальный статический автоклав
        arr['array_diametr'] = type_d[1]
        arr['layout_id'] = 1
        arr['type_id'] = 1
    } else if (opros.type_autovlave_id == 2) { // Горизонтальный роторный автоклав
        arr['array_diametr'] = type_d[2]
        arr['layout_id'] = 1
        arr['type_id'] = 2
    } else if (opros.type_autovlave_id == 3) { // Вертикальный автоклав
        arr['array_diametr'] = type_d[3]
        arr['layout_id'] = 2
        arr['type_id'] = 3
    }

    return arr

}   


export const checkUserSetting = (opros, param) => {

    let opros_user_setting = param.opros_user_setting
    let col_autoclave = param.col_autoclave
    let diametr = param.diametr
    let max_waiting_time = param.max_waiting_time
    let total_weight_basket = param.total_weight_basket
    let total_price = param.total_price
    let col_basket = param.col_basket

    if (col_autoclave === 0) {
        return false
    }

    if (total_price === 0) {
        return false
    } 

    if (opros_user_setting.col_autoclave_min > 0 && col_autoclave < opros_user_setting.col_autoclave_min) {
        return false
    }

    if (opros_user_setting.col_autoclave_max > 0 && col_autoclave > opros_user_setting.col_autoclave_max) {
        return false
    }

    if (opros_user_setting.col_basket_min > 0 && col_basket < opros_user_setting.col_basket_min) {
        return false
    }

    if (opros_user_setting.col_basket_max > 0 && col_basket > opros_user_setting.col_basket_max) {
        return false
    }

    if (opros_user_setting.diametr_min > 0 && diametr < opros_user_setting.diametr_min) {
        return false
    }

    if (opros_user_setting.diametr_max > 0 && diametr > opros_user_setting.diametr_max) {
        return false
    }

    if (opros_user_setting.max_waiting_time > 0 && max_waiting_time > opros_user_setting.max_waiting_time) {
        return false
    }

    if (opros_user_setting.basket_weight > 0 && total_weight_basket > opros_user_setting.basket_weight) {
        return false
    }

    return true

}

export const getWeightPoddonOrProkl = (diametr,opros,param) => {
    let weight = 0
    let arr_weight_accessories = param.arr_weight_accessories
    

    let accessories_id = 0

    if (String(opros.type_podacha_water_id) === "1") { // водный каскад
        accessories_id = 3 // прокладки
    } else if (String(opros.type_podacha_water_id) === "2") { // водный душ
        accessories_id = 4 // поддоны
    }

    let layout_id = param.layout_id
    let type_id = param.type_id

    for (let key in arr_weight_accessories) {
        let arr = key.split('_')
        if (
            String(arr[0]) === String(layout_id) &&
            String(arr[1]) === String(type_id) &&
            String(arr[2]) === String(diametr) && 
            String(arr[3]) === String(accessories_id)
        ) {
            weight = parseFloat(arr_weight_accessories[key])
        }
    }


    return weight
}

export const getTotalWeightBasket = (diametr,opros,param) => {
    let col_product_in_basket = colProductInBasket(diametr,opros,param)
    let col_poddon_or_prokl = colPoddonOrProkl(diametr,opros,param)
    let weight_poddon_or_prokl = getWeightPoddonOrProkl(diametr,opros,param)

    // Вес корзины = Кол-во упаковок в корзине * (вес Нетто + вес упаковки) + вес поддонов / вес прокладок

    let weight = col_product_in_basket * (parseFloat(opros.weight_netto) + parseFloat(opros.weight_upak)) + col_poddon_or_prokl * weight_poddon_or_prokl

    let weight_kg = weight / 1000

    return weight_kg
}


export const getPriceAccessories = (diametr,param,accessories_id) => {

    let price = 0

    let layout_id = param.layout_id
    let type_id = param.type_id
    let arr_price_accessories = param.arr_price_accessories

    for (let key in arr_price_accessories) {
        let arr = key.split('_')
        if (
            String(arr[0]) === String(layout_id) &&
            String(arr[1]) === String(type_id) &&
            String(arr[2]) === String(diametr) && 
            String(arr[3]) === String(accessories_id)
        ) {
            price = parseFloat(arr_price_accessories[key])
        }
    }

    return price

}

export const getTotalPriceAutoclave = (diametr,col_basket,opros,param,ob_col_autoclave) => {
    
    let col_autoclave = ob_col_autoclave.length

    // Кол-во корзин
    let total_col_basket = 0
    if (String(opros.complect_basket_id) === '1') {
        total_col_basket = col_autoclave * col_basket
    } else if (String(opros.complect_basket_id) === '2') {
        total_col_basket = (col_autoclave + 1) * col_basket
    } else if (String(opros.complect_basket_id) === '3') {
        total_col_basket = (col_autoclave + 2) * col_basket
    } else if (String(opros.complect_basket_id) === '4') {
        total_col_basket = opros.user_col_basket
    }

    let total_price_basket = 0
    // Если тип укладки - корзины и в опциях стоит что корзины нужны
    if (String(opros.type_ukladka) === '1' && String(opros.option_basket) === '1') {
        total_price_basket = getPriceAccessories(diametr,param,1) * total_col_basket
    }
    
    // Кол-во тележек
    // кол-во корзин в одном автоклаве*2
    let total_col_telejka = 0
    if (String(opros.complect_telejka_id) === '2') {
        total_col_telejka = col_basket
    } else if (String(opros.complect_telejka_id) === '3') {
        total_col_telejka = col_basket * 2
    } else if (String(opros.complect_telejka_id) === '4') {
        total_col_telejka = opros.user_col_telejka
    } else if (String(opros.complect_telejka_id) === '5') {
        total_col_telejka = total_col_basket
    }

    let total_price_telejka = 0
    // Если в опциях стоит что тележки нужны
    if (String(opros.option_telejka) === '1') {
        total_price_telejka = getPriceAccessories(diametr,param,2) * total_col_telejka
    }

    let total_price_prokl_poddon = 0
    let col_prokl_poddon = 0
    let is_prokl = 0
    let is_poddon = 0
    let height_poddon = 0
    let col_prokl_poddon_basket = 0


    if (String(opros.type_podacha_water_id) === "1") { // водный каскад
        
        // Кол-во прокладок
        // суммарное кол-во корзин * кол-во слоев 

        let col_poddon_or_prokl = colPoddonOrProkl(diametr,opros,param)
        col_prokl_poddon_basket = col_poddon_or_prokl
        col_prokl_poddon = total_col_basket * col_poddon_or_prokl
        if (String(opros.option_prokl) === '1') {
            is_prokl = 1
            total_price_prokl_poddon = getPriceAccessories(diametr,param,3) * col_prokl_poddon
        }

    } 

    if (String(opros.type_podacha_water_id) === "2" || String(opros.type_ukladka) === '2') { // водный душ

        // Кол-во поддонов
        // суммарное кол-во корзин * кол-во слоев

        let col_poddon_or_prokl = colPoddonOrProkl(diametr,opros,param)
        col_prokl_poddon_basket = col_poddon_or_prokl
        let height_poddon = heightPoddonOrProkl(diametr,opros,param)

        col_prokl_poddon = total_col_basket * col_poddon_or_prokl
        if (String(opros.option_poddon) === '1') {
            is_poddon = 1
            total_price_prokl_poddon = getPriceAccessories(diametr,param,4) * col_prokl_poddon
        }

    }



    // Цена за ПНР
    let total_price_pnr = 0
    let total_col_pnr = 0
    if (String(opros.option_pnr) === '1') {
        total_col_pnr = col_autoclave
        total_price_pnr = total_col_pnr * getPriceAccessories(diametr,param,5)
    }

    // Цена за вертикальную дверь
    let total_price_door = 0
    let total_col_door = 0
    if (String(opros.option_door) === '1') {
        total_col_door = col_autoclave
        total_price_door = total_col_door * getPriceAccessories(diametr,param,6)
    }

    // Цена за вторую дверь
    let total_price_sdoor = 0
    let total_col_sdoor = 0
    if (String(opros.option_sdoor) === '1') {
        total_col_sdoor = col_autoclave
        total_price_sdoor = total_col_sdoor * getPriceAccessories(diametr,param,7)
    }
    let price_one_autoclave = getPriceOneAutoclave(diametr,col_basket,opros,param,ob_col_autoclave)
    
    let price_autoclave = 0
    if (price_one_autoclave > 0) {
        price_autoclave = col_autoclave * price_one_autoclave + total_price_basket + total_price_telejka + total_price_prokl_poddon + total_price_pnr + total_price_door + total_price_sdoor
    }

    let total_price_autoclave = col_autoclave * price_one_autoclave
 
    return {
        price_autoclave: price_autoclave,
        col_prokl_poddon: col_prokl_poddon,
        col_prokl_poddon_basket: col_prokl_poddon_basket,
        total_price_prokl_poddon: total_price_prokl_poddon,
        total_price_basket: total_price_basket,
        total_price_telejka: total_price_telejka,
        total_col_basket: total_col_basket,
        total_col_telejka: total_col_telejka,
        price_one_autoclave: price_one_autoclave,
        col_autoclave: col_autoclave,
        total_price_autoclave: total_price_autoclave,
        total_col_door: total_col_door,
        total_price_door: total_price_door,
        total_col_sdoor: total_col_sdoor,
        total_price_sdoor: total_price_sdoor,
        total_col_pnr: total_col_pnr,
        total_price_pnr: total_price_pnr,
        is_prokl: is_prokl,
        is_poddon: is_poddon,
        height_poddon: height_poddon,
    }

}


export const setPrintDataSchema = (diametr,col_basket,opros_user_setting,opros,param,app) => {
    param['opros_user_setting'] = opros_user_setting
    param['diametr'] = diametr
    param['col_basket'] = col_basket

    let user = param.user
    let col_product_in_basket = colProductInBasket(diametr,opros,param)
    let ob_col_autoclave = colNeedAutoclave(diametr,col_basket,opros,param)
    let max_waiting_time = getMaxWaitingTime(diametr,col_basket,opros,param)
    let total_weight_basket = getTotalWeightBasket(diametr,opros,param)
    let total_data_price = getTotalPriceAutoclave(diametr,col_basket,opros,param,ob_col_autoclave)
    let total_price = total_data_price.price_autoclave

    param['col_autoclave'] = ob_col_autoclave.length
    param['max_waiting_time'] = max_waiting_time
    param['col_product_in_basket'] = col_product_in_basket
    param['total_weight_basket'] = total_weight_basket
  
    total_weight_basket = total_weight_basket.toFixed(1)

    let check_user_setting = checkUserSetting(opros, param)

    let style = {}

    if (check_user_setting) 
        style['color'] = 'rgb(0,0,0)'
    else
        style['color'] = 'rgb(230,230,230)'


    let content = (
        <div className="contentDataItog" style={style}>
            
            {app.translate['calculate_itog_col_auto']}: {ob_col_autoclave.length} {app.translate['calculate_itog_col_auto_unit']}<br/>
            {app.translate['schema_price']}: { total_price > 0 ? total_price + ' €' : '-'}<br/>
            {app.translate['max_suspend_calculate']}: { max_waiting_time } {app.translate['max_suspend_calculate_unit']}<br/>
            Вес корзины{app.translate['calculate_itog_basket_weight']}: { total_weight_basket } {app.translate['calculate_itog_basket_weight_unit']}<br/>
            
        </div>

    )

    return content
}



export const heightPoddonOrProkl = (diametr,opros,param) => {

    let razmer_upak_height = parseFloat(opros.razmer_upak_height)

    let additional_height = opros.height_between

    return razmer_upak_height + additional_height
}

export const colPoddonOrProkl = (diametr,opros,param) => {

    let col_in_row_3 = 0

    let razmer_upak_height = parseFloat(opros.razmer_upak_height)

    let basket = getBasketDimestion(diametr,param)

    // Если водный душ – поддоны (без корзины). В характеристиках есть цена поддона. 

    // additional_height - вытащить в исходные данные
    // Прибавка к высоте слоя.
    // по умолчанию ставим 4 или 14, но можно менять

    let additional_height

    /*if (String(opros.type_podacha_water_id) === "1") { // водный каскад
        additional_height = 4
    } else if (String(opros.type_podacha_water_id) === "2") { // водный душ
        additional_height = 14
    }*/

    additional_height = opros.height_between

    if (basket.height) {
        col_in_row_3 = Math.floor(basket.height / (razmer_upak_height + additional_height))
    }

    return col_in_row_3
}


export const colProductInBasket = (diametr,opros,param) => {

    let col_in_basket = 0

    // Корзины+продкладки
    // Берем размеры корзины для автоклава Ду1000. Зная размер упаковки мы можем посчитать 
    // кол-во упаковки, которое помещается на нижний слой корзины. 
    // Сверху первого слоя кладется прокладка толщиной 4 мм. 
    // Получаем высоту слоя равную высоте упаковки + 4 мм. 
    // Далее считаем кол-во слоев, умещающихся в одну упаковку.
    // Получившиеся кол-во слоев умножаем на кол-во банок в одном слое и получаем кол-во банок в корзине.
    // Далее умножаем получившееся кол-во банок на кол-во корзин в одном автоклаве.

    let razmer_upak_length = parseFloat(opros.razmer_upak_length)
    let razmer_upak_width = parseFloat(opros.razmer_upak_width)
    let razmer_upak_height = parseFloat(opros.razmer_upak_height)

    let basket = getBasketDimestion(diametr,param)

    // Если водный душ – поддоны (без корзины). В характеристиках есть цена поддона. 

    let additional_height

    /*if (String(opros.type_podacha_water_id) === "1") { // водный каскад
        additional_height = 4
    } else if (String(opros.type_podacha_water_id) === "2") { // водный душ
        additional_height = 14
    }*/

    additional_height = opros.height_between

    if (basket.length && basket.width && basket.height) {

        let col_in_row_1 = Math.floor(basket.length / razmer_upak_length)
        let col_in_row_2 = Math.floor(basket.width / razmer_upak_width)
        let col_in_row_3 = Math.floor(basket.height / (razmer_upak_height + additional_height))

        col_in_basket = col_in_row_1 * col_in_row_2 * col_in_row_3

    }

    return col_in_basket
}

export const getBasketDimestion = (diametr,param) => {

    let arr_busket_dimension = param.arr_busket_dimension

    let layout_id = param.layout_id
    let type_id = param.type_id

    let data = {}

    for (let key in arr_busket_dimension) {
        let arr = key.split('_')
        if (
            String(arr[0]) === String(layout_id) &&
            String(arr[1]) === String(type_id) &&
            String(arr[2]) === String(diametr)
        ) {
            let pole = arr[3]
            data[pole] = parseFloat(arr_busket_dimension[key])
        }
    }

    return data

}

export const mainParamColAutoclave = (diametr,col_basket,opros,param) => {

    let col_product_in_basket = colProductInBasket(diametr,opros,param)
    let productivity_hour = opros.productivity_hour
    let productivity_min = productivity_hour / 60

    let time_nagrev = parseFloat(opros.time_nagrev)
    let time_viderjka = parseFloat(opros.time_viderjka)
    let time_ohlajdenia = parseFloat(opros.time_ohlajdenia)
    let time_zagruzka = parseFloat(opros.time_zagruzka)

    let time_circle = time_nagrev + time_viderjka + time_ohlajdenia + time_zagruzka

    let time_to_fill_basket = col_basket * Math.ceil((col_product_in_basket) / productivity_min)

    return {
        col_product_in_basket:col_product_in_basket,
        productivity_hour:productivity_hour,
        productivity_min:productivity_min,
        time_nagrev:time_nagrev,
        time_viderjka:time_viderjka,
        time_ohlajdenia:time_ohlajdenia,
        time_zagruzka:time_zagruzka,
        time_circle:time_circle,
        time_to_fill_basket:time_to_fill_basket,
    }

}

export const colNeedAutoclave = (diametr,col_basket,opros,param) => {

    let ob_param_col_autoclave = mainParamColAutoclave(diametr,col_basket,opros,param)

    let col_product_in_basket = ob_param_col_autoclave.col_product_in_basket
    let productivity_min = ob_param_col_autoclave.productivity_min
    let time_circle = ob_param_col_autoclave.time_circle

    let col_need_autoclave = 0

    let arr = [] // массив с автоклавами

    let time_before = 0

    let commot_time_to_fill_basket = 0

    let col_autoclave_now = 0

    while (true) {

        let time_to_fill_basket = col_basket * Math.ceil((col_product_in_basket) / productivity_min)

        commot_time_to_fill_basket = commot_time_to_fill_basket + time_to_fill_basket

        if (time_to_fill_basket === 0) break

        let ob = {}
        let time_start = time_to_fill_basket + time_before
        let time_end = time_start + time_circle 
        
        ob['time_to_fill_basket'] = time_to_fill_basket
        ob['time_start'] = time_start
        ob['time_end'] = time_end

        // Проверяе не закончился ли цикл
        
        /*for (let i = 0; i < arr.length; i++) {
            let ob_check = arr[i]
            if (ob_check.time_end < time_start) return arr
        }*/

        arr.push(ob)

        col_autoclave_now++

        /* ПРОВЕРКА ОСТАНОВКИ ЦИКЛА */
        let total_col_now = col_autoclave_now * col_basket * col_product_in_basket / time_circle // шт/мин - если такое кол-во автоклавов
        if (total_col_now >= productivity_min) break
        //////////

        time_before = time_start

    }

    return arr

}

export const getPriceOneAutoclave = (diametr,col_basket,opros,param,ob_col_autoclave) => {
    let arr_price_autoclave = param.arr_price_autoclave
    let layout_id = param.layout_id
    let type_id = param.type_id

    for (let key in arr_price_autoclave) {
        let arr = key.split('_')
        if (
            String(arr[0]) === String(layout_id) &&
            String(arr[1]) === String(type_id) &&
            String(arr[2]) === String(diametr) &&
            String(arr[3]) === String(col_basket)
        ) {
            return parseFloat(arr_price_autoclave[key])
        }
    }    
    return 0
}

export const getMaxWaitingTime = (diametr,col_basket,opros,param) => {

    let col_product_in_basket = colProductInBasket(diametr,opros,param)
    let productivity_hour = opros.productivity_hour
    let productivity_min = productivity_hour / 60

    let time_to_fill_basket = col_basket * Math.ceil((col_product_in_basket) / productivity_min)

    return time_to_fill_basket

}


export const getDayHourAutoclave = (opros) => {
    let day_hour = 24
    let productivity_unit = opros.productivity_unit
    let col_work_hour = opros.col_work_hour

    if (productivity_unit === 'шт / смена') {
        if (col_work_hour !== '') {
            day_hour = col_work_hour
        }
    }

    return day_hour * 60
}