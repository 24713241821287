import { useEffect, useState, useContext } from "react"
import { saveOpros } from '../../http/oprosAPI.js'
import { CSSTransition } from 'react-transition-group'
import { Context } from '../../index.js'
import { observer } from 'mobx-react'

const DeliveryOpros = observer((params) => {

    const [opros, setOpros] = useState({})
    const [isSaved, setIsSaved] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const { app } = useContext(Context)

    const changeOpros = (e) => {
        setOpros({
            ...opros,
            [e.target.name]: e.target.value,
        })
    }

    const blurValue = async (e,pole) => {
        let data = await saveOpros(opros)
    }

    const saveBtnOpros = async () => {
        setDisabled(true)
        let data = await saveOpros(opros)
        setDisabled(false)
        setIsSaved(true)
        setTimeout(() => {
            setIsSaved(false)
        }, 2000)
    }

    useEffect(() => {
        setOpros(params.opros)
    },[params])

    return (
        <div className="card">
            <div className="card-body">
                
                <div className="row">
                    <div className="col-12">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAY5JREFUSEvtluExBEEQhb+LABGQASJABIgAESACZCADMkAERwSIABkQAfWpma02Neum1K77c111dXdb3fN6Xne/7QlzssmccFkA/xvzJdXXwC6w/IcMjgDjmywC7wE3TVF1pwdguzU+Ap8DZ62BFb+5AT8BJyGhZ+C97yJD3riG0Vv3sYFNZge4L7MaElhqM9VOhR2+BFiCzTGBy+aKzXoKXEbwIW9c6+pXYDU1mbf2/7eNDexcTxPWLbA/BnCLBHSNNuSNW4AvAGs/KNUL4BoDVaoPgatCa32m3lqXgwYunVeFw27OAhLDunmOzaXaKG3ryTPq7BrwMgP4DdAvm8Ax2Q9gI89yuQjEuYtqY8BjOlGhiNprzFZiZiUAO7cuFZoUm0hVQHLMZ/qRKfZbDRZcK0U/lkiwTHXW7TvAJeOH1bZMNfW4h1ZfBDmB7GKJfBEojTVTrUxoJrAH6Sh90ayhmQtSmsmYcIyxpt66uof9tldLYd6hBPOA3o0iZWJz+dGvlmCX8GKh7+mP4R9/Ad29WB+zMouoAAAAAElFTkSuQmCC" style={{height: 18}}/> &nbsp; Выберите тип доставки: 
                        &nbsp; &nbsp; 
                        <select value={opros.delivery} name="delivery" onChange={(e) => changeOpros(e)}>
                            <option value="0">{ app.translate['delivery_type_exworks'] }</option>
                            <option value="1">{ app.translate['delivery_type_lodos_russia'] }</option>
                        </select>
                    </div>
                </div>

                { opros.delivery == 1 && 

                    <div class="row" style={{display: 'flex'}}>
            
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3 pr-3">
                            <div className="title_create_kp">{ app.translate['finance_delivery_russia_without_nds_text'] }</div>
                            <div className="input_create_kp">
                                <input type="text" value={opros.delivery_price_1} name="delivery_price_1" onChange={(e) => changeOpros(e)} placeholder={ app.translate['finance_delivery_russia_without_nds_text'] } class="form-control"/>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3 pr-3">
                            <div className="title_create_kp">{ app.translate['delivery_type_lodos_russia'] }, €</div>
                            <div className="input_create_kp">
                                <input type="text" value={opros.delivery_price_2} name="delivery_price_2" onChange={(e) => changeOpros(e)} placeholder={ app.translate['delivery_type_lodos_russia'] } class="form-control"/>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3 pr-3">
                            <div className="title_create_kp">{ app.translate['finance_dt_without_nds_text'] }, { app.translate['finance_price_unit'] }</div>
                            <div className="input_create_kp">
                                <input type="text" value={opros.dt_price} name="dt_price" onChange={(e) => changeOpros(e)} class="form-control"/>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3 pr-3">
                            <div className="title_create_kp">{ app.translate['finance_tam_oper_without_nds'] }, { app.translate['finance_price_unit'] }</div>
                            <div className="input_create_kp">
                                <input type="text" value={opros.tam_posh_price} name="tam_posh_price" onChange={(e) => changeOpros(e)} class="form-control"/>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3 pr-3">
                            <div className="title_create_kp">{ app.translate['finance_sertificate'] }, { app.translate['finance_price_unit'] }</div>
                            <div className="input_create_kp">
                                <input type="text" value={opros.sertif_price} name="sertif_price" onChange={(e) => changeOpros(e)} class="form-control"/>
                            </div>
                        </div>
                
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3 pr-3">
                            <div className="title_create_kp">{ app.translate['finance_staff'] }, %</div>
                            <div className="input_create_kp">
                                <input type="text" value={opros.kurs_pr} name="kurs_pr" onChange={(e) => changeOpros(e)} class="form-control"/>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3 pr-3">
                            <div className="title_create_kp">{ app.translate['country_delivery'] }</div>
                            <div className="input_create_kp">
                                <input type="text" value={opros.delivery_country} name="delivery_country" onChange={(e) => changeOpros(e)} class="form-control"/>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3 pr-3">
                            <div className="title_create_kp">{ app.translate['city_delivery'] }</div>
                            <div className="input_create_kp">
                                <input type="text" value={opros.delivery_city} name="delivery_city" onChange={(e) => changeOpros(e)} class="form-control"/>
                            </div>
                        </div>

                    </div>

                }

                
                <div className="col-12 mt-3 mb-3">
                    <button className="btn btn-success btn-sm" disabled={disabled} onClick={saveBtnOpros}>{ app.translate['save'] }</button>
                </div>

                            
                <CSSTransition in={isSaved} className='fadeInOut' timeout={300} unmountOnExit>
                    <div className="col-12">
                        <div className="alert border-0 border-start border-5 border-success alert-dismissible fade show py-2">
                            <div className="d-flex align-items-center">
                                <div className="font-35 text-success"><i className="bx bxs-check-circle"></i>
                                </div>
                                <div className="ms-3">
                                    <h6 className="mb-0 text-success">{ app.translate['title_success'] }</h6>
                                    <div>{ app.translate['text_opros_success'] }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>




            </div>
        </div>
    )
})

export default DeliveryOpros