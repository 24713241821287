import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppStore from './store/AppStore';
import UserStore from './store/UserStore.js'
import CalculateStore from './store/CalculateStore.js'

export const Context = createContext()

ReactDOM.render(
  <Context.Provider value={{
    user: new UserStore(),
    app: new AppStore(),
    calculate: new CalculateStore(),
  }}>
    <App />
  </Context.Provider>,
  document.getElementById('root')
);
