import React, {useState, useContext} from 'react'
import { Link } from 'react-router-dom'
import {
    colProductInBasket,
    colNeedAutoclave,
    getMaxWaitingTime,
    getTotalWeightBasket,
    getTotalPriceAutoclave,
    checkUserSetting,
} from './calculateFunction.js'

import { observer } from 'mobx-react'
import { Context } from '../../index.js'

const PrintAutoclave = observer( (data) => {

    const { app } = useContext(Context)

    const {calculate} = useContext(Context)
    const [showFull, setShowFull] = useState(false)

    let diametr = data.diametr
    let col_basket = data.col_basket
    let opros_user_setting = data.opros_user_setting
    let opros = data.opros
    let param = data.param
    let best_price = data.best_price

    let isBestPrice = false
    if (best_price.diametr === diametr && best_price.col_basket === col_basket) {
        isBestPrice = true
    }

    param['opros_user_setting'] = opros_user_setting
    param['diametr'] = diametr
    param['col_basket'] = col_basket

    let user = param.user
    let col_product_in_basket = colProductInBasket(diametr,opros,param)
    let ob_col_autoclave = colNeedAutoclave(diametr,col_basket,opros,param)
    let max_waiting_time = getMaxWaitingTime(diametr,col_basket,opros,param)
    let total_weight_basket = getTotalWeightBasket(diametr,opros,param)
    let total_data_price = getTotalPriceAutoclave(diametr,col_basket,opros,param,ob_col_autoclave)
    let total_price = total_data_price.price_autoclave

    param['col_autoclave'] = ob_col_autoclave.length
    param['max_waiting_time'] = max_waiting_time
    param['col_product_in_basket'] = col_product_in_basket
    param['total_weight_basket'] = total_weight_basket
    param['total_price'] = total_price

    total_weight_basket = total_weight_basket.toFixed(1)

    let check_user_setting = checkUserSetting(opros, param)

    let style = {}

    if (check_user_setting) 
        style['color'] = 'rgb(0,0,0)'
    else
        style['color'] = 'rgb(230,230,230)'

    let props = data.props
    


    
    return (
        <div>
            <div className="contentDataItog" style={style}>
                
                <div className="autoclave_header">

                    {isBestPrice &&
                    <span style={{color: 'red', fontWeight: '600'}}>{ app.translate['best_price'] }</span>
                    }
                      
                    &nbsp;
                    &nbsp;

                    <Link to={ "/calculate-schema/" + opros.opros_id + "/" + diametr + "/" + col_basket }><span className="bx bx-trending-up" style={style}></span></Link>
                    
                    &nbsp;
                    &nbsp;

                    <a href={"https://thermalsystemsquotes.ru/autoclave/docx.php?opros_id="+opros.opros_id+"&diametr="+diametr+"&col_basket="+col_basket+"&col_prokl="+total_data_price.col_prokl_poddon+"&ca="+ob_col_autoclave.length+"&pa="+total_data_price.total_price_autoclave+"&cb="+total_data_price.total_col_basket+"&pb="+total_data_price.total_price_basket+"&ct="+total_data_price.total_col_telejka+"&pt="+total_data_price.total_price_telejka+"&pp="+total_data_price.total_price_prokl_poddon+"&cpnr="+total_data_price.total_col_pnr+"&ppnr="+total_data_price.total_price_pnr+"&cdoor="+total_data_price.total_col_door+"&pdoor="+total_data_price.total_price_door+"&csdoor="+total_data_price.total_col_sdoor+"&psdoor="+total_data_price.total_price_sdoor+"&hp="+total_data_price.height_poddon+"&lang="+app.lang}><span className="bx bxs-file-doc" style={style}></span></a>
                    

                    &nbsp;
                    &nbsp;

                    <span onClick={ () => setShowFull(!showFull) } className="bx bx-cog"></span>

                    &nbsp;
                    &nbsp;

                    <Link to={ "/calculate-price-table/" + opros.opros_id + "/" + diametr + "/" + col_basket }><span className="bx bx-euro" style={style}></span></Link>
                </div>
                
                <div className="autoclave_main">
                    { "D" + diametr + " / " + "J" + col_basket}<br/>
                    { app.translate['col_autoclaves'] }: {ob_col_autoclave.length} { app.translate['amout_unit'] }<br/>
                    { app.translate['price_autoclaves'] }: { total_price > 0 ? total_price + ' €' : '-'}<br/>
                    { app.translate['max_suspend_calculate'] }: { max_waiting_time } { app.translate['max_suspend_calculate_unit'] }<br/>
                    { app.translate['weight_basket_calculate'] }: { total_weight_basket } { app.translate['weight_basket_calculate_unit'] }<br/>
                </div>

                { showFull && 

                <div>

                    <div style={{backgroundColor: 'rgb(245,245,245)', padding: 5, marginTop: 5}}>

                        <strong>{ app.translate['upak_on_basket_calculate'] }</strong><br/>
                        { app.translate['col_in_basket'] }: {col_product_in_basket} { app.translate['amout_unit'] }<br/>
                        { app.translate['col_upak_in_autoclaves'] }: {col_basket * col_product_in_basket} { app.translate['amout_unit'] }<br/>
                        { app.translate['col_upak_in_all_autoclaves'] }: {ob_col_autoclave.length * col_basket * col_product_in_basket} { app.translate['amout_unit'] }<br/>
                        
                        <strong>{ app.translate['col_poddon_calculate'] }</strong><br/>
                        { app.translate['col_in_basket'] }: {total_data_price.col_prokl_poddon_basket} { app.translate['amout_unit'] }<br/>
                        { app.translate['col_upak_in_autoclaves'] }: {col_basket * total_data_price.col_prokl_poddon_basket} { app.translate['amout_unit'] }<br/>
                        { app.translate['col_upak_in_all_autoclaves'] }: {ob_col_autoclave.length * col_basket * total_data_price.col_prokl_poddon_basket} { app.translate['amout_unit'] }<br/>
                        { app.translate['col_upak_in_all_autoclaves_dop'] }: {total_data_price.col_prokl_poddon} { app.translate['amout_unit'] }<br/>
                        
                        <strong>{ app.translate['col_telejka_calculate'] }</strong>: {total_data_price.total_col_telejka} { app.translate['amout_unit'] }<br/>

                    </div>

                </div>

                }
            </div>
        </div>
    )

} )

export default PrintAutoclave