import {useEffect, useState, useContext} from 'react'
import { Button, Modal } from 'react-bootstrap';
import { saveComment, cancelPriceLog } from '../../../http/catalogAPI.js'
import { observer } from 'mobx-react'
import { Context } from '../../../index.js'

const LogModal = observer( (data) => {

    const { app } = useContext(Context)

    let showLogModal = data.showLogModal
    let price_log_id_temp = data.price_log_id

    const [show, setShow] = useState(showLogModal)
    const [comment, setComment] = useState('')
    const [price_log_id, setPriceLog] = useState(price_log_id_temp)

    useEffect(()=>{
        setComment('')
        setShow(showLogModal)
        setPriceLog(price_log_id_temp)
    },[showLogModal,price_log_id_temp])

    const handleClose = async () => {
        setShow(false)
        data.changeShowLogModal(false)
    }

    const handleSave = async () => {
        if (comment.length > 2) {
            await saveComment(price_log_id, comment)
            handleClose()
        } else {
            alert(app.translate['type_comment'])
        }
    }

    const cancelSave = async () => {
        
        // Ищем последнюю цену. Если её нет, ставим 0
        // Удаляем price_log_id
        let {new_value} = await cancelPriceLog(price_log_id)

        // Меняем на новое значение в таблице
        data.changeKeyOb(new_value, data.valueKey)
    
        handleClose()

    }

    return (
        <Modal show={show} onHide={handleSave}>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 18}}>{ app.translate['comment_change_field'] }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <textarea onChange={(e)=>setComment(e.target.value)} style={{height: 140}} className="form-control form-control-sm">{comment}</textarea>
                </div>
            </Modal.Body>

            <div className="p-3">

                <div className="row">
                    <div className="col-6 text-start">
                        <Button variant="danger" className="btn btn-sm" onClick={cancelSave}>
                            { app.translate['cancel'] }
                        </Button>
                    </div>
                    <div className="col-6 text-end">
                        <Button variant="primary" className="btn btn-sm" onClick={handleSave}>
                            { app.translate['save_and_close'] }
                        </Button>
                    </div>
                </div>

            </div>

        </Modal>
    );

})

export default LogModal