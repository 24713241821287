import { useEffect, useState, useContext } from 'react'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import InputProductCategory from './view/InputProductCategory.js'
import InputProduct from './view/InputProduct.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { getProductCategory } from '../../http/catalogAPI.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'

const ProductCategory = observer( () => {

    const { app } = useContext(Context)

    const [productCategory,setProductCategory] = useState([])

    const getFullData = async () => {
        let array_temp = await getProductCategory()
        setProductCategory(array_temp)
    }
    
    useEffect( () => {
        getFullData()
    },[])

    let BreadCrumbArray = [
        {
            link: '/product-category',
            name: app.translate['handbook_product'],
            icon: 'bx bx-calculator',
        }
    ]



    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={app.translate['product_categories']} data={BreadCrumbArray}/>
                        
                        <div style={{overflow:'auto'}}>
                            <div className="card p-4">

                                <InputProductCategory update={getFullData}></InputProductCategory>
                                
                                {productCategory.map((data) => {
                                    return (
                                        <div class="mb-4">
                                            <InputProductCategory update={getFullData} name={data.name} name_ru={data.name_ru} name_en={data.name_en} name_es={data.name_es} product_category_id={data.product_category_id}></InputProductCategory>                
                                            {data.array_product.map((product)=>{
                                                return (
                                                    <InputProduct update={getFullData} product_category_id={product.product_category_id} name={product.name} name_ru={product.name_ru} name_en={product.name_en} name_es={product.name_es} product_id={product.product_id}></InputProduct>
                                                )
                                            })}
                                            <InputProduct product_category_id={data.product_category_id} update={getFullData}></InputProduct>
                                        </div>
                                    )
                                })}


                                

                            </div>
                        </div>

                        
                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
    
} )

export default ProductCategory