export const MAIN_ROUTER = '/calculate-list'
export const SIGNIN_ROUTER = '/sign-in'
export const CALCULATE_DATA_ROUTER = '/calculate-data/:opros_id'
export const CALCULATE_ITOG = '/calculate-itog/:opros_id'
export const CALCULATE_LIST_ROUTER = '/calculate-list'
export const CALCULATE_OPROS_ROUTER = '/calculate-data'
export const AUTOCLAVE_ROUTER = '/autovlave-price'
export const ACCESSORIES_ROUTER = '/accessories-price'
export const ACCESSORIES_WEIGHT = '/accessories-weight'
export const PRODUCT_CATEGORY_ROUTER = '/product-category'
export const PACKAGES_ROUTER = '/packages'
export const CALCULATE_SCHEMA = '/calculate-schema/:opros_id/:diametr/:col_basket'
export const CREATE_DOCX_ROUTER = '/create-docx/:opros_id'
export const SPECIFICATION_PRICE_ROUTER = '/specification-price/:layout_id/:type_id'
export const LAND_SETTING = '/lang_setting'
export const ACCESSORIES_NAME_ROUTER = '/accessories-name'
export const SPECIFICATION_ROUTER = '/specification-name'
export const IMAGE_FILE_ROUTER = '/image/:file_id'
export const CALCULATE_PRICE_TABLE_ROUTER = '/calculate-price-table/:opros_id/:diametr/:col_basket'

