import { useState, useContext } from 'react'
import { 
    addLang,
 } from '../../../http/catalogAPI.js'
import { observer } from 'mobx-react'
import { Context } from '../../../index.js'

const InputLangAdd = observer((props) => {

    const { app } = useContext(Context)

    const [key,setKey] = useState('')
    const [name_ru,setNameRu] = useState('')
    const [name_en,setNameEn] = useState('')
    const [name_es,setNameEs] = useState('')
    const [comment,setComment] = useState('')

    const onAdd = async () => {

        try {
            let data = await addLang(key,name_ru,name_en,name_es,comment)
            props.updateLang()
            setKey('')
            setNameRu('')
            setNameEn('')
            setNameEs('')
            setComment('')
        } catch(err) {
            alert(app.translate['add_mistake'])
        }

    }
    
    return (
        <tr style={{backgroundColor: 'rgb(220,220,220)'}}>
            <td><input type="text" value={key} onChange={e => setKey(e.target.value)} className="form-control form-control-sm"/></td>
            <td><input type="text" value={name_ru} onChange={e => setNameRu(e.target.value)} className="form-control form-control-sm"/></td>
            <td><input type="text" value={name_en} onChange={e => setNameEn(e.target.value)} className="form-control form-control-sm"/></td>
            <td><input type="text" value={name_es} onChange={e => setNameEs(e.target.value)} className="form-control form-control-sm"/></td>
            <td><input type="text" value={comment} onChange={e => setComment(e.target.value)} className="form-control form-control-sm"/></td>
            <td><input type="button" onClick={ onAdd } className="btn btn-primary btn-sm form-control form-control-sm" value={ app.translate['button_add'] }/></td>
        </tr>
    )
})

export default InputLangAdd