import { useEffect, useState, useContext } from 'react'
import { setPriceSpecification, getPriceSpecification, getComment, getSpecification, setImageSpecification } from '../../http/catalogAPI.js'
import { layout, layout_type, type_d, col_basket_array, getArrDataSpecification } from '../../utils/catalog.js'
import LogModal from './view/LogModal.js'
import LogCommentModal from './view/LogCommentModal.js'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'
import { Link } from 'react-router-dom'

const SpecificationPriceTable = observer( (props) => {

    const { app } = useContext(Context)

    const layout_id = props.layout_id
    const type_id = props.type_id
    const specification_id = props.specification_id
    const type_podacha_water_id = props.type_podacha_water_id
    const props_name = props.name
    const [name, setName] = useState(props.name)
    const type_podacha_water_name = props.type_podacha_water_name

    useEffect(() => {
        let name_tmp = props_name
        if (layout_id == 1) {
            name_tmp = name_tmp.replace('{dimansion}',app.translate['length'])
        } else {
            name_tmp = name_tmp.replace('{dimansion}',app.translate['height'])
        }
        setName(name_tmp)
    }, [app, layout_id])

    const [arrPrice,setArrayPrice] = useState([])
    const [price_log_id, setPriceLog] = useState(0)
    const [showLogModal, setShowLogModal] = useState(false)
    const [valueKey, setValueKey] = useState('')

    const [price_log_comment, setPriceLogComment] = useState([])
    const [showLogCommentModal, setShowLogCommentModal] = useState(false)
    
    const changePriceSpecification = (e, key_ob) => {
        setArrayPrice({...arrPrice,[key_ob]: e.target.value}) 
        setValueKey(key_ob)
    }
    
    const changeFileSpecification = async (e, key_ob) => {
        setArrayPrice({...arrPrice,[key_ob]: 0}) 
        const res = await setImageSpecification(key_ob, e.target.files[0]);
        setArrayPrice({...arrPrice,[key_ob]: res.file_id}) 
    }

    const changeKeyOb = (value, key_ob) => {
        setArrayPrice({...arrPrice,[key_ob]: value}) 
    }
    
    const blurPriceSpecification = async (e, key_ob) => {
        const res = await setPriceSpecification(key_ob, e.target.value)
        console.log(res)

        if ('price_log_id' in res) {
            setPriceLog(res.price_log_id)
            setShowLogModal(true)
        }

    }

    const changeShowLogModal = async (val) => {
        setShowLogModal(val)
    }

    const changeShowLogCommentModal = async (val) => {
        setShowLogCommentModal(val)
    }

    const loadComment = async (key_ob) => {
        const res = await getComment(key_ob,'specification_prices')
        console.log(res)
        setPriceLogComment(res)
        setShowLogCommentModal(true)
    }

    const load_data = async() => {
        const arr_res = await getPriceSpecification()
        let arr = getArrDataSpecification(layout, layout_type, type_d, col_basket_array, type_podacha_water_id, specification_id, arr_res)
        setArrayPrice(arr)
    }
    

    useEffect(() => {
        load_data()
    },[])


    
    let content = []

    // Заголовок таблицы
    let row = (
        <tr style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>
            <td></td>
            {
                col_basket_array.map(col_basket=>{
                    return (
                        <td colSpan="2">
                            {col_basket} { app.translate['col_basket_unit'] }
                        </td>
                    )
                }) 
            }
        </tr>
    )
    
    content.push(row)


    if (specification_id == 6) {

        for (let d_key in type_d[type_id]) {

            let d = type_d[type_id][d_key]
            
            let row = (
                <tr>
                    <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>{d}</td>
                    {
                        col_basket_array.map(col_basket=>{

                            let key_ob = layout_id + "_" + type_id + "_" + d + "_" + col_basket + "_" + type_podacha_water_id + "_" + specification_id

                            return (
                                <>
                                    <td style={{borderRight: 0}}>
                                        { (arrPrice[key_ob] && arrPrice[key_ob] !== 0) ? <div style={{marginBottom: 10}}><Link to={'/image/' + arrPrice[key_ob]} target="_blank">изображение</Link></div> : <div style={{marginBottom: 10}}>-</div> }
                                        <input className="form-control form-control-sm" type="file" onChange={(e) => changeFileSpecification(e,key_ob)} />
                                    </td>
                                    <td style={{borderLeft: 0, textAlign: 'center'}}></td>
                                </>
                            )
                        }) 
                    }
                </tr>
            )

            content.push(row)
        
        }

    } else {

        for (let d_key in type_d[type_id]) {

            let d = type_d[type_id][d_key]
            
            let row = (
                <tr>
                    <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>{d}</td>
                    {
                        col_basket_array.map(col_basket=>{

                            let key_ob = layout_id + "_" + type_id + "_" + d + "_" + col_basket + "_" + type_podacha_water_id + "_" + specification_id

                            return (
                                <>
                                    <td style={{borderRight: 0}}>
                                        <input className="form-control form-control-sm" type="number" step="any" value={arrPrice[key_ob] ? arrPrice[key_ob] : 0} onBlur={(e) => blurPriceSpecification(e,key_ob)} onChange={(e) => changePriceSpecification(e,key_ob)} />
                                    </td>
                                    <td style={{borderLeft: 0, textAlign: 'center'}}>
                                        {arrPrice[key_ob] !== 0  && 
                                            <i onClick={()=>loadComment(key_ob)} style={{cursor: 'pointer'}} className='bx bx-info-circle'></i>
                                        }
                                    </td>
                                </>
                            )
                        }) 
                    }
                </tr>
            )

            content.push(row)

        }

    }


    let BreadCrumbArray = [
        {
            link: '/calculate-list',
            name: app.translate['calculate_list_title'],
            icon: 'bx bx-calculator',
        }
    ]

    return (

        <div className="card">
            { specification_id !== 5 && specification_id !== 6 ? <div style={{color: 'rgb(150,150,150)'}} className="mx-4 mt-3">{ type_podacha_water_name }</div> : <div style={{color: 'rgb(150,150,150)'}} className="mx-4 mt-0">&nbsp;</div> }
            
            <div className="mx-4 mb-3 mt-1"><h5>{ name }</h5></div> 
            
            <table className="tableAutoclavePrice">
                <tbody>
                    <tr style={{fontWeight: 600, backgroundColor: 'rgb(240,240,240'}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={col_basket_array.length * 2}>{ app.translate['col_basket'] }</td>
                    </tr>
                    {content.map((row)=>{
                        return row
                    })}
                </tbody>
            </table>

            <LogModal 
                changeKeyOb={changeKeyOb} 
                valueKey={valueKey} 
                showLogModal={showLogModal} 
                changeShowLogModal={changeShowLogModal}
                price_log_id={price_log_id}
            ></LogModal>

            <LogCommentModal 
                showLogCommentModal={showLogCommentModal} 
                changeShowLogCommentModal={changeShowLogCommentModal} 
                price_log_comment={price_log_comment}
            ></LogCommentModal>
        
        </div>

    )

} )

export default SpecificationPriceTable