export const calculateItog = (opros, course) => {
    if (opros.delivery === 0) return 0
    let price_del_tam = calculateItogPart(opros, course)
    price_del_tam = price_del_tam + Math.round(price_del_tam * opros.kurs_pr / 100);
    return price_del_tam
}   

export const calculateItogPart = (opros, course) => {
    if (opros.delivery === 0) return 0
    return Math.round( opros.delivery_price_1 + opros.dt_price / course + opros.tam_posh_price / course + opros.delivery_price_2 / course + opros.sertif_price / course )
}