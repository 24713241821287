import { useState, useContext } from 'react'
import { saveSpecification, deleteSpecification } from '../../../http/catalogAPI.js'
import { CSSTransition } from 'react-transition-group'
import { observer } from 'mobx-react'
import { Context } from '../../../index.js'

const InputSpecifications =  observer((data) => {

    const { app } = useContext(Context)

    let specification_id = data.specification_id ?? 0
    let prop_name = data.name ?? ''
    let prop_name_ru = data.name_ru ?? ''
    let prop_name_en = data.name_en ?? ''
    let prop_name_es = data.name_es ?? ''

    const [name,setName] = useState(prop_name)
    const [name_ru,setNameRu] = useState(prop_name_ru)
    const [name_en,setNameEn] = useState(prop_name_en)
    const [name_es,setNameEs] = useState(prop_name_es)
    const [isSaved, setIsSaved] = useState(false)

    const showSaved = () => {
        setIsSaved(true)
        setTimeout(()=>{
            setIsSaved(false)
        },1000)
    }

    const changeSpecification = async () => {
        const res = await saveSpecification(specification_id, {
            name: name,
            name_ru: name_ru,
            name_en: name_en,
            name_es: name_es,
        })
        data.update()
        if (specification_id === 0) {
            setName('')
            setNameRu('')
            setNameEn('')
            setNameEs('')
        }
        console.log(res)
        if (specification_id !== 0) showSaved()
    }

    const removeSpecification = async () => {
        const res = await deleteSpecification(specification_id);           
        data.update()
    }

    let btn_text = specification_id !== 0 ? app.translate['button_change'] : app.translate['button_add']
    let class_btn = specification_id !== 0 ? 'btn btn-sm btn-primary form-control form-control-sm' : 'btn btn-sm btn-success form-control form-control-sm'
    
    
    return (
        <div>
            <div className="row mt-3">
                <div className="col-2">
                    <div className="">
                        <input type="text" onChange={(e)=>setName(e.target.value)} value={name} placeholder={ app.translate['name_package'] } className="form-control form-control-sm"/>
                    </div>
                </div>
                <div className="col-2">
                    <div className="">
                        <input type="text" onChange={(e)=>setNameRu(e.target.value)} value={name_ru} placeholder={ app.translate['name_package'] + ' RU' } className="form-control form-control-sm"/>
                    </div>
                </div>
                <div className="col-2">
                    <div className="">
                        <input type="text" onChange={(e)=>setNameEn(e.target.value)} value={name_en} placeholder={ app.translate['name_package'] + ' EN' } className="form-control form-control-sm"/>
                    </div>
                </div>
                <div className="col-2">
                    <div className="">
                        <input type="text" onChange={(e)=>setNameEs(e.target.value)} value={name_es} placeholder={ app.translate['name_package'] + ' ES' } className="form-control form-control-sm"/>
                    </div>
                </div>
                <div className="col-2">
                    <input type="button" onClick={changeSpecification} value={btn_text} className={class_btn}/>
                </div>
                <div className="col-2 text-right">
                    {specification_id !== 0 &&
                        <div><span onClick={removeSpecification} className="bx bx-x" style={{color: 'red', cursor: 'pointer', fontWeight: 600}}></span></div>
                    }
                </div>
            </div>
            <div className="row">
                <div class="col-8">
                    <div className="">
                        <CSSTransition key="key_mist" in={isSaved} className='fadeInOut mt-2' timeout={300} unmountOnExit>
                            <div>
                                <div className="alert border-0 border-start border-5 border-success alert-dismissible fade show py-2">
                                    <div className="d-flex align-items-center">
                                        <div className="font-35 text-success"><i className="bx bxs-check-circle"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h6 className="mb-0 text-success">{ app.translate['title_success'] }</h6>
                                            <div>{ app.translate['data_saved'] }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>    
                    </div>
                </div>
                <div className="col-4"></div>
            </div>
        </div>

    )
})

export default InputSpecifications