import { useEffect, useState, useContext } from 'react'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import InputPackages from './view/InputPackages.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { getPackages } from '../../http/catalogAPI.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'

const Packages = observer( () => {

    const { app } = useContext(Context)

    const [packages,setPackages] = useState([])

    const getFullData = async () => {
        let array_temp = await getPackages()
        setPackages(array_temp)
    }
    
    useEffect( () => {
        getFullData()
    },[])

    let BreadCrumbArray = [
        {
            link: '/packages',
            name: app.translate['handbook_package'],
            icon: 'bx bx-calculator',
        }
    ]



    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={ app.translate['handbook_package'] } data={BreadCrumbArray}/>
                        
                        <div style={{overflow:'auto'}}>
                            <div className="card p-4">

                                <InputPackages update={getFullData}></InputPackages>
                                
                                {packages.map((data) => {
                                    return (
                                        <div className="">
                                            <InputPackages update={getFullData} name={data.name} name_ru={data.name_ru} name_en={data.name_en} name_es={data.name_es} package_id ={data.package_id }></InputPackages>                
                                         </div>
                                    )
                                })}


                                

                            </div>
                        </div>

                        
                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
    
} )

export default Packages