export const printDate = (str, with_time = 1) => {
    
    let arr = str.split('T')
    let arr_date = arr[0].split('-')
    let date = arr_date[2] + "." + arr_date[1] + "." + arr_date[0] + " г.";

    if (with_time == 1) {
        let arr_time= arr[1].split('.')
        let time = arr_time[0]

        date = date + " " + time
    }

    return date

}

export const translateArray = (array,app) => {
    array.map((ob) => {
        ob.name = app.translate[ob.key]
    })
}

export const translateArrayLayout = (array,app) => {
    Object.keys(array).map((key_ob) => {
        array[key_ob]['name'] = app.translate[array[key_ob]['key']]
    })
}

export const translateArrayLayoutType = (array,app) => {
    Object.keys(array).map((key_ob) => {
        Object.keys(array[key_ob]).map((key_ob2) => {
            array[key_ob][key_ob2]['name'] = app.translate[array[key_ob][key_ob2]['key']]
        })
    })
}