import { useEffect, useState, useContext } from 'react'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import InputAccessories from './view/InputAccessories.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { getAccessories } from '../../http/catalogAPI.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'

const AccessoriesCatalog = observer( () => {

    const { app } = useContext(Context)

    const [accessories,setAccessories] = useState([])

    const getFullData = async () => {
        let array_temp = await getAccessories()
        setAccessories(array_temp)
        console.log(array_temp)
    }
    
    useEffect( () => {
        getFullData()
    },[])

    let BreadCrumbArray = [
        {
            link: '/accessories-name',
            name: app.translate['menu_reference_accessories_name'],
            icon: 'bx bx-calculator',
        }
    ]



    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={ app.translate['menu_reference_accessories_name'] } data={BreadCrumbArray}/>
                        
                        <div style={{overflow:'auto'}}>
                            <div className="card p-4">

                                <InputAccessories update={getFullData}></InputAccessories>
                                
                                {accessories.map((data) => {
                                    return (
                                        <div className="">
                                            <InputAccessories update={getFullData} sort={data.sort} name={data.name} name_ru={data.name_ru} name_en={data.name_en} name_es={data.name_es} id ={data.id } key ={data.id }></InputAccessories>                
                                         </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>

                <Footer/>

            </div>

        </div>

    )
    
} )

export default AccessoriesCatalog