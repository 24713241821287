import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import { observer } from 'mobx-react'

const BreadCrumb = observer((props) => {

    const [title,setTitle] = useState()
    const [BreadCrumb,setBreadCrumb] = useState([])

    useEffect(() => {
        
        setTitle(props.title)
        setBreadCrumb(props.data)

    },[props])

    return (
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">{title}</div>
            <div className="ps-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                        {BreadCrumb.map((one) => {
                            return (
                                <li className="breadcrumb-item"><Link style={{color: 'rgb(150,150,150)'}} to={one.link}><i className={one.icon}> {one.name}</i></Link></li>
                            )
                        })}
                        
                    </ol>
                </nav>
            </div>
        </div>
    )

})

export default BreadCrumb