import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { layout, layout_type, type_d, col_basket_array, getArrData } from '../../utils/catalog.js'
import { getSpecification } from '../../http/catalogAPI.js'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { 
    type_podacha_water_list
} from '../calculate/calculate_db.js'
import SpecificationPriceTable from './SpecificationPriceTable.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'
import { translateArrayLayout, translateArrayLayoutType, translateArray } from '../helpers/common'

const SpecificationPrice = observer( () => {

    const { app } = useContext(Context)

    const {layout_id, type_id} = useParams()
    const [arraySpecification,setArraySpecification] = useState([])

    useEffect(() => {
        translateArrayLayout(layout,app)
        translateArrayLayoutType(layout_type,app)
        translateArray(type_podacha_water_list,app)
    },[app.lang])

    useEffect( async () => {
        const arraySpecificationApi = await getSpecification()
        setArraySpecification(arraySpecificationApi)
    },[])

    let BreadCrumbArray = [
        {
            link: '/calculate-list',
            name: app.translate['calculate_list_title'],
            icon: 'bx bx-calculator',
        }
    ]

    return (

        
        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={app.translate['menu_specifications'] + " (" + layout[layout_id]['name'] + ", " + layout_type[layout_id][type_id]['name'] + ")"} data={BreadCrumbArray}/>
                        
                        <div style={{overflow:'auto'}}>
                                
                            {type_podacha_water_list.map(type_podacha_water => {

                                return (
                                    <>
                                        {

                                            arraySpecification.map(specification => {

                                                if (type_podacha_water.type_podacha_water_id == 2 && specification.specification_id == 5) {
                                                    return 
                                                }

                                                if (type_podacha_water.type_podacha_water_id == 2 && specification.specification_id == 6) {
                                                    return 
                                                }

                                                return (
                                                    <div className="m-2" key={type_podacha_water.type_podacha_water_id}>
                                                        <SpecificationPriceTable
                                                            key={"key_" + specification.specification_id + "_" + type_podacha_water.type_podacha_water_id}
                                                            type_podacha_water_name={type_podacha_water.name}
                                                            name={specification['name_' + app.lang]}
                                                            specification_id={specification.specification_id}
                                                            type_podacha_water_id={type_podacha_water.type_podacha_water_id}
                                                            layout_id={layout_id}
                                                            type_id={type_id}
                                                        ></SpecificationPriceTable>
                                                    </div>
                                                )

                                            })

                                        }
                                    </>
                                )
                                
                            })}

                        </div>
                        
                    </div>

                </div>

                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>
    )

} )

export default SpecificationPrice