import React, {useState, useContext} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { CALCULATE_ITOG } from '../../utils/consts.js'
import { getFullOprosData } from '../../http/productAPI.js'
import { getOpros } from '../../http/oprosAPI.js'
import { type_autovlave_list, col_door_list, type_podacha_water_list } from './calculate_db.js'
import {
    getNameAutoclave,
    getArrayDiametr,
    colNeedAutoclave,
    setPrintDataSchema,
    getDayHourAutoclave
} from './calculateFunction.js'
import { CSSTransition } from 'react-transition-group'
import { opros_default, opros_user_setting_default } from './oprosDefault.js'
import { layout, layout_type, type_d, col_basket_array, getArrData } from '../../utils/catalog.js'
import { getOprosUserSetting, saveOprosUserSetting } from '../../http/oprosAPI.js'
import { 
    getPriceAutoclave, 
    getBusketDimension, 
    getAccessories, 
    getPriceAccessories,
    getWeightAccessories
} from '../../http/catalogAPI.js'
import {Context} from '../../index.js'
import { observer } from 'mobx-react'

const CalculateSchema = observer( () => { 

    const { user, app } = useContext(Context)

    const [opros,setOpros] = useState(opros_default)
    const [opros_user_setting,setOprosUserSetting] = useState(opros_user_setting_default)
    const [nameAutoclave,setNameAutoclave] = useState('')
    const {opros_id,diametr,col_basket} = useParams()
    const [arrayDiametr,setArrayDiametr] = useState([])
    const [content,setContent] = useState([])
    const [param,setParam] = useState({})
    const [loaded,setLoaded] = useState(false)
    const [tempParam,setTempParam] = useState(false)
    const [tempParam2,setTempParam2] = useState(false)
    const [arrayNeedAutoclave,setArrayNeedAutoclave] = useState([])

    React.useEffect(async () => {

        let opros_data = await getOpros(opros_id)
        setOpros(opros_data)

        // Устанавливаем пользовательские настройки
        let opros_user_setting = await getOprosUserSetting(opros_id)
        if (Object.keys(opros_user_setting).length > 0) {
            setOprosUserSetting(opros_user_setting)
        }
        opros_user_setting.opros_id = opros_id
        
        // Формируем название автоклава (заголовок)
        setNameAutoclave(getNameAutoclave(opros_data,app))

        // Формируем массив с диаметрами для текущего автоклава
        let arrayDiametrData = getArrayDiametr(opros_data,type_d)['array_diametr']
        let layout_id = getArrayDiametr(opros_data,type_d)['layout_id']
        let type_id = getArrayDiametr(opros_data,type_d)['type_id']

        setArrayDiametr(arrayDiametrData)

        // Подгружаем цены на автоклавы
        let arr_price_autoclave = await getPriceAutoclave()

        // Подгружаем цены на комплектующие и массив с комплектующими
        let arr_price_accessories = await getPriceAccessories()
        let arr_weight_accessories = await getWeightAccessories()
        let arr_accessories = await getAccessories()

        // Размеры корзин
        let arr_busket_dimension = await getBusketDimension()

        let temp_param = {
            layout_id:layout_id,
            type_id:type_id,
            arr_price_autoclave:arr_price_autoclave,
            arr_price_accessories:arr_price_accessories,
            arr_weight_accessories:arr_weight_accessories,
            arr_accessories:arr_accessories,
            arr_busket_dimension:arr_busket_dimension,
            user:user,
        }
        setParam(temp_param)

        let array_need_autoclave = colNeedAutoclave(diametr,col_basket,opros_data,temp_param)

        setArrayNeedAutoclave(array_need_autoclave)

        setLoaded(true)
        
    },[app.lang])


    if (loaded === true) {
        let content_temp = setPrintDataSchema(diametr,col_basket,opros_user_setting,opros,param,app)
        setContent(content_temp)
        setLoaded(false)
    }

    let array_content = []

    // Количетсво часов в смене. 
    // Кол-во минут в дне, для определения ширины листа
    let day_min = getDayHourAutoclave(opros)
    let width_one_min = 1000 / day_min
    let width_24_hour = day_min * width_one_min
    let col_hour = day_min / 60 + 1
    let array_hour = []
    for (let i = 0; i < col_hour; i++) {
        array_hour.push(i)
    }

    // 3508 - ширина А4 в px

    // Считаем сколько нужно дополнительно времени на заполнение коризны (или экономия времени)
    let i_a = 0

    // Суммарное время в начале для заполнения корзин во всех автоклавах
    let full_time_to_fill_basket = 0
    if (arrayNeedAutoclave.length > 0) {
        full_time_to_fill_basket = arrayNeedAutoclave[0].time_to_fill_basket * arrayNeedAutoclave.length
    }
    // Параметр, на который увеличится или уменьшится время на заполнение корзин в следующих интеррациях
    let diff_ttfb = 0
    if (arrayNeedAutoclave.length > 0) {
        diff_ttfb = full_time_to_fill_basket - arrayNeedAutoclave[0].time_end
    }

    
    arrayNeedAutoclave.map((one_autoclave) => {

        let i = 0
        let array_row = []
        let col_on_one_list = 0
        
        while (true) {

            let time_autoclave = one_autoclave.time_end - one_autoclave.time_start
            let time_to_fill_basket = 0
            if (i === 0) {
                time_to_fill_basket = one_autoclave.time_to_fill_basket
            } else {
                time_to_fill_basket = one_autoclave.time_to_fill_basket + diff_ttfb
            }

            if (i === 0) {
                if (day_min - time_autoclave - time_to_fill_basket > 0) {
                    col_on_one_list = 1 + Math.floor((day_min - time_autoclave - time_to_fill_basket) / (time_autoclave + time_to_fill_basket + diff_ttfb))
                } else {
                    col_on_one_list = 1
                }
            }

            let width_1 = time_to_fill_basket * width_one_min;
            let width_2 = time_autoclave * width_one_min;

            let margin_left = 0
            if (i == 0) {
                margin_left = one_autoclave.time_start * width_one_min - time_to_fill_basket * width_one_min
            } else {
                margin_left = 0
            }


            let width_full = width_1 + width_2

            let one = (
                <div class="time-full-circle-autoclave" style={{marginLeft: margin_left, width: width_full}}>
                   <div className="time-autoclave-fill" style={{width: width_1, marginRight: 1}}><div className="timeToFillBasket_font">{time_to_fill_basket} { app.translate['time_to_fill_basket_unit'] }</div></div>
                   <div className="time-autoclave-action" style={{width: width_2, marginRight: 1}}><div className="timeAutoclave_font">{time_autoclave} { app.translate['time_autoclave_unit'] }</div></div>
                </div>
            )

            array_row.push(one)
            i++

            if (i > (col_on_one_list - 1)) break
        }

        array_content.push(array_row)

    })
    
    let row_interator = 0
    
    let BreadCrumbArray = [
        {
            link: '/calculate-list',
            name: app.translate['calculate_list_title'],
            icon: 'bx bx-home-circle',
        },
        {
            link: '/calculate-data/'+opros_id,
            name: app.translate['start_data'],
            icon: 'bx bx-list-check',
        },
        {
            link: '/calculate-itog/'+opros_id,
            name: app.translate['result_calculate'],
            icon: 'bx bx-calculator',
        },
        {
            link: '/calculate-schema/'+opros_id+'/'+diametr+'/'+col_basket,
            name: app.translate['schema'],
            icon: 'bx bx-bar-chart-square',
        }
    ]

    return (

        <div>
            
            <div className="wrapper">

                <Header/> 

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={ app.translate['schema_breadcrumb'] } data={BreadCrumbArray}/>

                        <div className="card">
                            <div className="card-body">

                                <div>

                                    <div className="card-title">
                                        <h5 className="mb-0">{nameAutoclave}</h5>
                                    </div>
                                    <br/>
                                    <div>{ app.translate['col_basket'] }: {col_basket} { app.translate['col_basket_unit'] }</div>
                                    <div>{ app.translate['diametr'] }: {diametr} { app.translate['diametr_unit_mm'] }</div>

                                    <hr/>

                                    {content}

                                    <hr/>
                                 
                                    <br/>

                                    <div className="schema_autoclave">
                                        <table>

                                            <tr>
                                                <td>
                                                    <div className=""></div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <div style={{width: width_24_hour, height: 2, backgroundColor: 'rgb(0,0,0)'}}></div>
                                                        {array_hour.map((i) => {
                                                            let margin_left = i * width_one_min * 60
                                                            return (
                                                                <div className="shtrih" style={{marginLeft: margin_left}}></div>
                                                            )
                                                        })}
                                                        {array_hour.map((i) => {
                                                            let width = width_one_min * 60
                                                            let margin_left = i * width_one_min * 60 - width / 2
                                                            return (
                                                                <div className="hour_shtrih" style={{marginLeft: margin_left, width: width}}>
                                                                    <div style={{textAlign: 'center'}}>{i} ч</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <br/>
                                                </td>
                                            </tr>
                                        
                                        {array_content.map((one) => {
                                            row_interator++
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="schemaAutoclaveTitleLeft">{ app.translate['schema_autoclave'] } №{row_interator}</div>
                                                    </td>
                                                    <td>
                                                        <div className="schema_autoclave_row">{one}</div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                        </table>
                                    </div>

                                    <br/>
                                    

                                </div>

                                
                            </div>
                        </div>

                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
} )

export default CalculateSchema