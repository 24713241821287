import { useEffect, useState, useContext } from 'react'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { ACCESSORIES_ROUTER } from '../../utils/consts.js'
import { getAccessories, setPriceAccessories, getComment, getPriceAccessories, setBusketDimension, getBusketDimension } from '../../http/catalogAPI.js'
import { layout, layout_type, type_d, getArrDataAccessories, getArrBusketDimension } from '../../utils/catalog.js'
import LogModal from './view/LogModal.js'
import LogCommentModal from './view/LogCommentModal.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'
import { translateArrayLayout, translateArrayLayoutType } from '../helpers/common'

const Accessories = observer(() => {

    const { app } = useContext(Context)

    const [arrPrice,setArrayPrice] = useState([])
    const [arrDimension,setArrDimension] = useState([])
    const [arrayAccessories,setArrayAccessories] = useState([])
    const [showLogModal, setShowLogModal] = useState(false)
    const [price_log_id, setPriceLog] = useState(0)
    const [valueKey, setValueKey] = useState('')
    const [price_log_comment, setPriceLogComment] = useState([])
    const [showLogCommentModal, setShowLogCommentModal] = useState(false)
    
    const changeShowLogModal = async (val) => {
        setShowLogModal(val)
    }

    const changeShowLogCommentModal = async (val) => {
        setShowLogCommentModal(val)
    }



    const changePriceAutoclave = (e, key_ob) => {
        setArrayPrice({...arrPrice,[key_ob]: e.target.value}) 
        setValueKey(key_ob)
    }

    const changeKeyOb = (value, key_ob) => {
        setArrayPrice({...arrPrice,[key_ob]: value}) 
    }

    const blurPriceAutoclave = async (e, key_ob) => {
        const res = await setPriceAccessories(key_ob, e.target.value)
        console.log(res)
        if ('price_log_id' in res) {
            setPriceLog(res.price_log_id)
            setShowLogModal(true)
        }
    }

    const loadComment = async (key_ob) => {
        const res = await getComment(key_ob,'accessories_prices')
        console.log(res)
        setPriceLogComment(res)
        setShowLogCommentModal(true)
    }

    
    const changeBusketDimension = (e, key_ob) => {
        setArrDimension({...arrDimension,[key_ob]: e.target.value}) 
    }

    const blurBusketDimension = (e, key_ob) => {
        const res = setBusketDimension(key_ob, e.target.value)
        console.log(res)
    }

    useEffect(() => {
        translateArrayLayout(layout,app)
        translateArrayLayoutType(layout_type,app)
    },[app.lang])

    useEffect( async () => {
        const arr_res = await getPriceAccessories()
        const arr_accessories = await getAccessories('price')
        setArrayAccessories(arr_accessories)
        let arr = getArrDataAccessories(layout, layout_type, type_d, arr_accessories, arr_res)
        setArrayPrice(arr)
    },[])

    useEffect( async () => {
        const arr_res = await getBusketDimension()
        let arr = getArrBusketDimension(layout, layout_type, type_d, arr_res)
        setArrDimension(arr)
    },[])


    
    let content = []

    // Заголовок таблицы
    let row = (
        <tr style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>
            <td></td>
            <td></td>
            <td></td>
            {
                arrayAccessories.map(ob=>{
                    return (
                        <td colspan="2">
                            {ob['name_' + app.lang]}
                        </td>
                    )
                }) 
            }
        </tr>
    )
    
    content.push(row)

    let layout_control = 0
    let type_id_control = 0
    for (let layout_id in layout) {
        for (let type_id in layout_type[layout_id]) {
            for (let d_key in type_d[type_id]) {
                let d = type_d[type_id][d_key]

                let layout_colspan = 1
                if (layout_control !== layout_id) {
                    layout_colspan = 0
                    for (let type_id_temp in layout_type[layout_id]) {
                        for (let d_key_temp in type_d[type_id_temp]) {
                            layout_colspan++
                        }
                    }
                }
                layout_control = layout_id

                let type_colspan = 1
                if (type_id_control !== type_id) {
                    type_colspan = Object.keys(type_d[type_id]).length
                }
                type_id_control = type_id

                let key_ob_width = layout_id + "_" + type_id + "_" + d + "_width"
                let key_ob_height = layout_id + "_" + type_id + "_" + d + "_height"
                let key_ob_length = layout_id + "_" + type_id + "_" + d + "_length"
                

                let row = (
                    <tr>
                        {(layout_colspan > 1) && 
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}} rowSpan={layout_colspan}><div className="rotate90">{layout[layout_id]['name']}</div></td>
                        }
                        {(type_colspan > 1) && 
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}} rowSpan={type_colspan}><div className="rotate90">{layout_type[layout_id][type_id]['name']}</div></td>
                        }
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>{d}</td>
                        {
                            arrayAccessories.map(ob=>{

                                let key_ob = layout_id + "_" + type_id + "_" + d + "_" + ob.id

                                return (
                                    <>
                                        <td style={{borderRight: 0}}>
                                            <input className="form-control form-control-sm" type="number" step="any" value={arrPrice[key_ob] ? arrPrice[key_ob] : 0} onBlur={(e) => blurPriceAutoclave(e,key_ob)} onChange={(e) => changePriceAutoclave(e,key_ob)} />
                                        </td>
                                        <td style={{borderLeft: 0, textAlign: 'center'}}>
                                            {arrPrice[key_ob] !== 0  && 
                                                <i onClick={()=>loadComment(key_ob)} style={{cursor: 'pointer'}} className='bx bx-info-circle'></i>
                                            }
                                        </td>
                                    </>
                                )
                            }) 
                        }
                    </tr>
                )

                content.push(row)

            }
        }
    }




    let BreadCrumbArray = [
        {
            link: '/accessories-price',
            name: app.translate['complect_price'],
            icon: 'bx bx-calculator',
        }
    ]


    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={app.translate['complect_price']} data={BreadCrumbArray}/>
                        
                        
                        <div style={{overflow:'auto'}}>
                            <div className="card">
                                
                                <table className="tableAutoclavePrice">
                                    <tbody>
                                        <tr style={{fontWeight: 600, backgroundColor: 'rgb(240,240,240'}}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colSpan={arrayAccessories.length * 2}>{ app.translate['complect'] }</td>
                                        </tr>
                                        {content.map((row)=>{
                                            return row
                                        })}
                                    </tbody>
                                </table>
                            
                            </div>
                        </div>

                        
                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

            <LogModal 
                changeKeyOb={changeKeyOb} 
                valueKey={valueKey}
                showLogModal={showLogModal} 
                changeShowLogModal={changeShowLogModal} 
                price_log_id={price_log_id}
            ></LogModal>
            <LogCommentModal 
                showLogCommentModal={showLogCommentModal} 
                changeShowLogCommentModal={changeShowLogCommentModal} 
                price_log_comment={price_log_comment}
            ></LogCommentModal>

        </div>

    )
    
})

export default Accessories