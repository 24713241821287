import { makeAutoObservable } from "mobx";

export default class AppStore {

    constructor() {
        this._lang = 'ru'
        this._translate = {}
        this._lang_file = []
        this._system = []
        makeAutoObservable(this)
    }

    setLang(lang) {
        this._lang = lang
    }

    get lang() {
        return this._lang
    }

    setSystem(system) {
        this._system = system
    }

    get system() {
        return this._system
    }

    setTranslate(translate) {
        console.log(translate)
        this._translate = translate
    }

    get translate() {
        return this._translate
    }

    setLangFile(lang_file) {
        this._lang_file = lang_file
    }

    get lang_file() {
        return this._lang_file
    }

}