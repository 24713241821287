import { useEffect, useState, useContext } from "react"
import { observer } from 'mobx-react'
import { Context } from '../../index.js'
import { useParams } from "react-router-dom"
import { getFile } from "../../http/userAPI.js"
import { Image } from "react-bootstrap"

const ImageFile = observer(() => {

    const { app } = useContext(Context)

    const {file_id} = useParams()
    
    const [arrLang,setArrLang] = useState([])
    const [file,setFile] = useState({})

    const load_data = async() => {
        const data = await getFile(file_id)
        setFile(data)
        console.log(data)
    }

    useEffect(() => {
        load_data()
    }, [file_id])

    return (

        <div>  
            <div className="wrapper">
                { file.path && <Image src={process.env.REACT_APP_API_URL + file.path} style={{maxWidth: "100%", textAlign: "center"}}/> }
            </div>
        </div>

    )
})

export default ImageFile