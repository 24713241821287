import {$authHost, $host} from './index.js'
import jwt_decode from 'jwt-decode'

export const login = async (email,pass) => {

    const {data} = await $host.post('api/user/login', {
        email, pass
    })


    localStorage.setItem('token',data.token)

    // Сохраняем в локальное хранилище логин и пароль
    // чтобы если он их изменит, мы могли его убрать
    localStorage.setItem('pass',pass)
    localStorage.setItem('email',email)

    return jwt_decode(data.token)

}

export const check = async () => {

    // Добавляем в запрос сохраненные логин и пароль
    const email = localStorage.getItem('email')
    const pass = localStorage.getItem('pass')

    const {data} = await $authHost.post('api/user/auth', {
        email, pass
    })
    localStorage.setItem('token',data.token)
    
    return jwt_decode(data.token)

}

export const getUserList = async () => {
    const {data} = await $authHost.post('api/user/get_user_list')
    return data
}


export const getFile = async (file_id) => {
    const {data} = await $host.get('api/user/get_file?file_id=' + file_id)
    return data
}