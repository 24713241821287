
import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PrintAutoclave from './PrintAutoclave.js'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import CalculatePriceTableOne  from './CalculatePriceTableOne'
import CalculatePriceTableId  from './CalculatePriceTableId'
import { 
    getOpros,
    getOprosUserSetting,
} from '../../http/oprosAPI.js'
import { 
    getPriceAutoclave, 
    getBusketDimension, 
    getAccessories, 
    getPriceAccessories,
    getWeightAccessories
} from '../../http/catalogAPI.js'
import {Context} from '../../index.js'
import { observer } from 'mobx-react'
import { opros_default, opros_user_setting_default } from './oprosDefault.js'
import {
    getNameAutoclave,
    getArrayDiametr,
    colNeedAutoclave,
    getTotalPriceAutoclave,
    colProductInBasket,
    getMaxWaitingTime,
    getTotalWeightBasket,
    checkUserSetting,
} from './calculateFunction.js'

import { type_d } from '../../utils/catalog.js'

const CalculatePriceTable = observer(() => {

    const { app, user } = useContext(Context)

    const { opros_id, diametr, col_basket } = useParams()

    const [opros,setOpros] = useState(opros_default)
    const [opros_user_setting,setOprosUserSetting] = useState(opros_user_setting_default)
    const [nameAutoclave,setNameAutoclave] = useState('')
    const [arrayDiametr,setArrayDiametr] = useState([])
    const [param,setParam] = useState({})
    const [loaded,setLoaded] = useState(false)
    const [itog,setItog] = useState({})


    useEffect(async () => {

        let opros_data = await getOpros(opros_id)
        setOpros(opros_data)

        // Устанавливаем пользовательские настройки
        let opros_user_setting = await getOprosUserSetting(opros_id)
        if (Object.keys(opros_user_setting).length > 0) {
            setOprosUserSetting(opros_user_setting)
        }
        opros_user_setting.opros_id = opros_id
        
        // Формируем название автоклава (заголовок)
        setNameAutoclave(getNameAutoclave(opros_data, app))

        // Формируем массив с диаметрами для текущего автоклава
        let arrayDiametrData = getArrayDiametr(opros_data,type_d)['array_diametr']
        let layout_id = getArrayDiametr(opros_data,type_d)['layout_id']
        let type_id = getArrayDiametr(opros_data,type_d)['type_id']

        setArrayDiametr(arrayDiametrData)

        // Подгружаем цены на автоклавы
        let arr_price_autoclave = await getPriceAutoclave()

        // Подгружаем цены на комплектующие и массив с комплектующими
        let arr_price_accessories = await getPriceAccessories()
        let arr_weight_accessories = await getWeightAccessories()
        let arr_accessories = await getAccessories()

        // Размеры корзин
        let arr_busket_dimension = await getBusketDimension()

        let param_one = {
            layout_id:layout_id,
            type_id:type_id,
            arr_price_autoclave:arr_price_autoclave,
            arr_price_accessories:arr_price_accessories,
            arr_weight_accessories:arr_weight_accessories,
            arr_accessories:arr_accessories,
            arr_busket_dimension:arr_busket_dimension,
            user:user,
            opros_user_setting:opros_user_setting,
        }

        setParam(param_one)

        let ob_col_autoclave = colNeedAutoclave(diametr,col_basket,opros_data,param_one)
        let total_data_price = getTotalPriceAutoclave(diametr,col_basket,opros_data,param_one,ob_col_autoclave)
        setItog(total_data_price)

        console.log(itog)

        setLoaded(true)
        
    },[app.lang])

    if (loaded === true && Object.keys(itog).length > 0) {

        console.log(itog)

    }

    



    let BreadCrumbArray = [
        {
            link: '/calculate-list',
            name: app.translate['calculate_list_title'],
            icon: 'bx bx-home-circle',
        },
        {
            link: '/calculate-data/'+opros_id,
            name: app.translate['start_data'],
            icon: 'bx bx-list-check',
        },
        {
            link: '/calculate-itog/'+opros_id,
            name: app.translate['result_calculate'],
            icon: 'bx bx-calculator',
        },
        {
            link: '/calculate-price-table/'+opros_id+'/'+diametr+'/'+col_basket,
            name: app.translate['finance_calculate'],
            icon: 'bx bx-euro',
        }
    ]

    return (
        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={app.translate['finance_calculate']} data={BreadCrumbArray}/>

                        <div className="card">
                            <div className="card-body">

                                <div className="card-title">
                                    <h5 className="mb-0">{nameAutoclave}</h5>
                                    <div style={{fontSize: 13, color: 'rgb(120,120,120)', marginTop: 5}}>{ app.translate['calculate_itog_col_basket'] } { col_basket } { app.translate['calculate_itog_col_basket_unit'] } | { app.translate['calculate_itog_diametr'] } { diametr } { app.translate['calculate_itog_diametr_unit'] }</div>
                                </div>

                                { opros.delivery == 1 && 

                                    <div className="row">
                                        
                                        <div className="col-6">
                                            <h6 className="mt-3 mb-3">{ app.translate['start_data_calculate_itog_table'] }</h6>
                                            <CalculatePriceTableId opros={opros}></CalculatePriceTableId>
                                        </div>

                                        <div className="col-6">
                                        
                                        </div>

                                    </div>
                                    
                                }

                                <div className="row">

                                    <div className="col-6">
                                        <h6 className="mt-3 mb-3">{ app.translate['price_apora_calculate_itog_table'] }</h6>
                                        <CalculatePriceTableOne opros={opros} itog={itog} for="apora"></CalculatePriceTableOne>
                                    </div>

                                    <div className="col-6">
                                        <h6 className="mt-3 mb-3">{ app.translate['price_client_calculate_itog_table'] }</h6>
                                        <CalculatePriceTableOne opros={opros} itog={itog} for="client"></CalculatePriceTableOne>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>
    )
})

export default CalculatePriceTable