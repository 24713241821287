import { $authHost, $host } from './index.js'

export const createDocx = async (opros_id) => {
    const {data} = await $host.get(
        'api/opros/create_docx?opros_id='+opros_id, 
        { responseType: 'blob' }
    )
    return data
}


export const deleteOpros = async (opros_id) => {
    const {data} = await $authHost.post('api/opros/delete_opros', {
        opros_id: opros_id
    })
    return data
}

export const copyOpros = async (opros_id) => {

    const {data} = await $authHost.post('api/opros/copy_opros', {
        opros_id: opros_id
    })

    return data

}

export const getOprosList = async (page = 1) => {

    const {data} = await $authHost.get('api/opros/get_opros_list', {
        params: {
            page: page
        }
    })

    return data

}

export const saveOpros = async (opros) => {

    const {data} = await $authHost.post('api/opros/save_opros', {
        opros: opros
    })

    return data

}

export const getOpros = async (opros_id) => {

    const {data} = await $authHost.get('api/opros/get_opros', {
        params: {
            opros_id: opros_id
        }
    })

    return data

}

export const getOprosUserSetting = async (opros_id) => {

    const {data} = await $authHost.get('api/opros/get_opros_user_setting', {
        params: {
            opros_id: opros_id
        }
    })

    return data

}

export const saveOprosUserSetting = async (opros_id, opros_user_setting) => {

    const {data} = await $authHost.post('api/opros/save_opros_user_setting', {
        opros_id: opros_id,
        opros_user_setting: opros_user_setting,
    })

    return data

}