import React, { useContext, useState } from 'react'
import { Context } from '../../index.js'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { createObjectLang } from '../../utils/fnc'
import { 
    SIGNIN_ROUTER,
    MAIN_ROUTER,
    CALCULATE_LIST_ROUTER,
    CALCULATE_OPROS_ROUTER,
    AUTOCLAVE_ROUTER,
    ACCESSORIES_ROUTER,
    ACCESSORIES_WEIGHT,
    PRODUCT_CATEGORY_ROUTER,
    PACKAGES_ROUTER,
    LAND_SETTING,
    ACCESSORIES_NAME_ROUTER,
    SPECIFICATION_ROUTER,
} from '../../utils/consts.js'


const Header = observer(() => {

    const {user, app} = useContext(Context)
    const history = useHistory()

    const [langNow,setLangNow] = useState(app.lang)

    const changeLang = (l) => {
        setLangNow(l)
        app.setLang(l)
        let translate = createObjectLang(app.lang_file,app.lang)
        app.setTranslate(translate)
    }
    const logOut = (e) => {

        user.setUser({})
        user.setIsAuth(false)

        delete localStorage.token;
        delete localStorage.pass;
        delete localStorage.email;

        history.push(SIGNIN_ROUTER)

    }

    return (
        <header>
            <div className="topbar d-flex align-items-center">

                <nav className="navbar navbar-expand">
            
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-style">
                            <li className="nav-item">
                                <Link to={MAIN_ROUTER} className="nav-link active" aria-current="page">
                                    <img src="/assets/images/logo.png" className="img_logo"/>
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    { app.translate['menu_oproses'] }
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link to={CALCULATE_LIST_ROUTER} className="dropdown-item">{ app.translate['menu_oproses_done'] }</Link></li>
                                    <li><Link to={CALCULATE_OPROS_ROUTER} className="dropdown-item">{ app.translate['menu_opros_create'] }</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    { app.translate['menu_reference_books'] }
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link to={AUTOCLAVE_ROUTER} className="dropdown-item">{ app.translate['menu_price_autoclaves'] }</Link></li>
                                    <li><Link to={ACCESSORIES_ROUTER} className="dropdown-item">{ app.translate['menu_price_complect'] }</Link></li>
                                    <li><Link to={ACCESSORIES_WEIGHT} className="dropdown-item">{ app.translate['menu_weight_complect'] }</Link></li>
                                    <li><Link to={PRODUCT_CATEGORY_ROUTER} className="dropdown-item">{ app.translate['menu_reference_books_products'] }</Link></li>
                                    <li><Link to={PACKAGES_ROUTER} className="dropdown-item">{ app.translate['menu_reference_books_packages'] }</Link></li>
                                    <li><Link to={ACCESSORIES_NAME_ROUTER} className="dropdown-item">{ app.translate['menu_reference_accessories_name'] }</Link></li>
                                    <li><Link to={SPECIFICATION_ROUTER} className="dropdown-item">{ app.translate['menu_reference_specification'] }</Link></li>
                                 </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                { app.translate['menu_specifications'] }
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link to={ "/specification-price/1/1" } className="dropdown-item">{ app.translate['menu_specifications_horiz_static'] }</Link></li>
                                    <li><Link to={ "/specification-price/1/2" } className="dropdown-item">{ app.translate['menu_specifications_horiz_rot'] }</Link></li>
                                    <li><Link to={ "/specification-price/2/3"}  className="dropdown-item">{ app.translate['menu_specifications_vert_static'] }</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    { app.translate['menu_lang_files'] }
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link to={ LAND_SETTING } className="dropdown-item">{ app.translate['menu_lang_setting'] }</Link></li>
                                    <li><Link to={PRODUCT_CATEGORY_ROUTER} className="dropdown-item">{ app.translate['menu_reference_books_products'] }</Link></li>
                                    <li><Link to={PACKAGES_ROUTER} className="dropdown-item">{ app.translate['menu_reference_books_packages'] }</Link></li>
                                    <li><Link to={ACCESSORIES_NAME_ROUTER} className="dropdown-item">{ app.translate['menu_reference_accessories_name'] }</Link></li>
                                    <li><Link to={SPECIFICATION_ROUTER} className="dropdown-item">{ app.translate['menu_reference_specification'] }</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={"/assets/images/" + langNow + ".png"}/> { langNow }
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li style={{cursor: 'pointer'}} onClick={()=>changeLang('ru')}><div style={{padding: 5, textAlign:'center'}}><img src={"/assets/images/ru.png"}/> ru</div></li>
                                    <li style={{cursor: 'pointer'}} onClick={()=>changeLang('es')}><div style={{padding: 5, textAlign:'center'}}><img src={"/assets/images/es.png"}/> es</div></li>
                                    <li style={{cursor: 'pointer'}} onClick={()=>changeLang('en')}><div style={{padding: 5, textAlign:'center'}}><img src={"/assets/images/en.png"}/> en</div></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                

                    <div className="user-box dropdown">
                        <a className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="/assets/images/icon_user.jpg" className="user-img" alt="user avatar"/>
                            <div className="user-info ps-3">
                                <p className="user-name mb-0">{user.user.name}</p>
                                <p className="designattion mb-0">{user.user.post}</p>
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><a className="dropdown-item" href=""><i className="bx bx-user"></i><span>{ app.translate['common_change_password'] }</span></a></li>
                            <li>
                                <div className="dropdown-divider mb-0"></div>
                            </li>
                            <li><a className="dropdown-item" href="" onClick={logOut}><i className='bx bx-log-out-circle'></i><span>{ app.translate['common_out'] }</span></a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
})

export default Header