import {makeAutoObservable} from 'mobx'

class CalculateStore {
    constructor() {

        this._diametr = 0
        this._col_basket = 0
        this._price = 0

        makeAutoObservable(this)

    }

    setDiametr (diametr) {
        this._diametr = diametr
    }

    setColbasket (col_basket) {
        this._col_basket = col_basket
    }

    setPrice (price) {
        this._price = price
    }

    get diametr() {
        return this._diametr
    }

    get col_basket() {
        return this._col_basket
    }

    get price() {
        return this._price
    }

}

export default CalculateStore