import React, { useContext } from 'react'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { AUTOCLAVE_ROUTER } from '../../utils/consts.js'
import AutoclavePrice from './AutoclavePrice.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'


const Autoclave = observer(() => {

    const { app } = useContext(Context)

    let BreadCrumbArray = [
        {
            link: '/autovlave-price',
            name: app.translate['menu_price_autoclaves'],
            icon: 'bx bx-calculator',
        }
    ]

    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={ app.translate['menu_price_autoclaves'] } data={BreadCrumbArray}/>
                        
                        <AutoclavePrice/>
                        
                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
    
})

export default Autoclave