import React, { useContext } from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {authRoutes,publicRoutes} from '../routes.js'
import {SIGNIN_ROUTER} from '../utils/consts.js'
import { observer } from 'mobx-react'
import { Context } from '../index.js'

const AppRouter = observer( () => {

    const {user} = useContext(Context)

    return (
        <Switch>
            {user.isAuth && authRoutes.map(({path,Component})=>    
                <Route key={path} path={path} component={Component} exact/>         
            )}
            {publicRoutes.map(({path,Component})=>    
                <Route key={path} path={path} component={Component} exact/>         
            )}
            <Redirect to={SIGNIN_ROUTER}/>
        </Switch>
    )

})

export default AppRouter