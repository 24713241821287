import { useEffect, useState, useContext } from 'react'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { ACCESSORIES_WEIGHT } from '../../utils/consts.js'
import { 
    getAccessories, 
    setWeightAccessories, 
    getWeightAccessories, 
    setBusketDimension, 
    getBusketDimension,
    getComment,
    setImageWeightAccessories
} from '../../http/catalogAPI.js'
import { layout, layout_type, type_d, getArrDataAccessories, getArrBusketDimension } from '../../utils/catalog.js'
import LogModal from './view/LogModal.js'
import LogCommentModal from './view/LogCommentModal.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js' 
import { translateArrayLayout, translateArrayLayoutType } from '../helpers/common'
import { Link } from 'react-router-dom'

const AccessoriesWeight = observer( () => {

    const { app } = useContext(Context)

    const [arrWeight,setArrayWeight] = useState([])
    const [arrDimension,setArrDimension] = useState([])
    const [arrayAccessories,setArrayAccessories] = useState([])
    const [tableLog,setTableLog] = useState('')
    const [price_log_id, setPriceLog] = useState(0)
    const [showLogModal, setShowLogModal] = useState(false)
    const [valueKey, setValueKey] = useState('')
    const [price_log_comment, setPriceLogComment] = useState([])
    const [showLogCommentModal, setShowLogCommentModal] = useState(false)
    
    const changeWeightAutoclave = (e, key_ob) => {
        setArrayWeight({...arrWeight,[key_ob]: e.target.value}) 
        setValueKey(key_ob)
    }

    const changeKeyOb = (value, key_ob, table) => {
        if (tableLog === 'accessories_weights') {
            setArrayWeight({...arrWeight,[key_ob]: value}) 
        } else if (tableLog === 'busket_dimensions') {
            setArrDimension({...arrDimension,[key_ob]: value}) 
        }
    }

    const blurWeightAutoclave = async (e, key_ob) => {
        const res = await setWeightAccessories(key_ob, e.target.value)

        if ('price_log_id' in res) {
            setPriceLog(res.price_log_id)
            setShowLogModal(true)
            setTableLog('accessories_weights')
        }
    }

    const changeFileWeight = async (e, key_ob) => {
        setArrayWeight({...arrWeight,[key_ob]: 0}) 
        const res = await setImageWeightAccessories(key_ob, e.target.files[0])
        setArrayWeight({...arrWeight,[key_ob]: res.file_id}) 
    }

    const changeShowLogModal = async (val) => {
        setShowLogModal(val)
    }

    const changeShowLogCommentModal = async (val) => {
        setShowLogCommentModal(val)
    }

    const loadComment = async (key_ob, table) => {
        const res = await getComment(key_ob, table)
        console.log(res)
        setPriceLogComment(res)
        setShowLogCommentModal(true)
    }


    
    const changeBusketDimension = (e, key_ob) => {
        setArrDimension({...arrDimension,[key_ob]: e.target.value}) 
        setValueKey(key_ob)
    }

    const blurBusketDimension = async (e, key_ob) => {
        const res = await setBusketDimension(key_ob, e.target.value)

        if ('price_log_id' in res) {
            setPriceLog(res.price_log_id)
            setShowLogModal(true)
            setTableLog('busket_dimensions')
        }
    }

    

    useEffect( async () => {
        const arr_res = await getWeightAccessories()
        const arr_accessories = await getAccessories('weight')
        setArrayAccessories(arr_accessories)
        let arr = getArrDataAccessories(layout, layout_type, type_d, arr_accessories, arr_res)
        setArrayWeight(arr)
    },[])

    useEffect( async () => {
        const arr_res = await getBusketDimension()
        let arr = getArrBusketDimension(layout, layout_type, type_d, arr_res)
        setArrDimension(arr)
    },[])

    useEffect(() => {
        translateArrayLayout(layout,app)
        translateArrayLayoutType(layout_type,app)
    },[app.lang])
    
    let content = []

    // Заголовок таблицы
    let row = (
        <tr style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>
            <td></td>
            <td></td>
            <td></td>
            {
                arrayAccessories.map(ob=>{
                    return (
                        <td colspan="2">
                            {ob.name}
                        </td>
                    )
                }) 
            }
            <td colspan="2">{ app.translate['width_mm'] }</td>
            <td colspan="2">{ app.translate['height_mm'] }</td>
            <td colspan="2">{ app.translate['length_mm'] }</td>
        </tr>
    )
    
    content.push(row)

    let layout_control = 0
    let type_id_control = 0
    for (let layout_id in layout) {
        for (let type_id in layout_type[layout_id]) {
            for (let d_key in type_d[type_id]) {
                let d = type_d[type_id][d_key]

                let layout_colspan = 1
                if (layout_control !== layout_id) {
                    layout_colspan = 0
                    for (let type_id_temp in layout_type[layout_id]) {
                        for (let d_key_temp in type_d[type_id_temp]) {
                            layout_colspan++
                        }
                    }
                }
                layout_control = layout_id

                let type_colspan = 1
                if (type_id_control !== type_id) {
                    type_colspan = Object.keys(type_d[type_id]).length
                }
                type_id_control = type_id

                let key_ob_width = layout_id + "_" + type_id + "_" + d + "_width"
                let key_ob_height = layout_id + "_" + type_id + "_" + d + "_height"
                let key_ob_length = layout_id + "_" + type_id + "_" + d + "_length"
                

                let row = (
                    <tr>
                        {(layout_colspan > 1) && 
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}} rowspan={layout_colspan}><div className="rotate90">{layout[layout_id]['name']}</div></td>
                        }
                        {(type_colspan > 1) && 
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}} rowspan={type_colspan}><div className="rotate90">{layout_type[layout_id][type_id]['name']}</div></td>
                        }
                        <td style={{fontWeight: 600, backgroundColor: 'rgb(245,245,255'}}>{d}</td>
                        {
                            arrayAccessories.map(ob=>{

                                let key_ob = layout_id + "_" + type_id + "_" + d + "_" + ob.id

                                if (ob.id == 8) {
                                    return (
                                        <>
                                            <td colspan="2">
                                                { (arrWeight[key_ob] && arrWeight[key_ob] !== 0) ? <div style={{marginBottom: 10}}><Link to={'/image/' + arrWeight[key_ob]} target="_blank">изображение</Link></div> : <div style={{marginBottom: 10}}>-</div> }
                                            <   input className="form-control form-control-sm" type="file" onChange={(e) => changeFileWeight(e,key_ob)} />
                                            </td>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <td style={{borderRight: 0}}>
                                                <input className="form-control form-control-sm" type="number" step="any" value={arrWeight[key_ob] ? arrWeight[key_ob] : 0} onBlur={(e) => blurWeightAutoclave(e,key_ob)} onChange={(e) => changeWeightAutoclave(e,key_ob)} />
                                            </td>
                                            <td style={{borderLeft: 0, textAlign: 'center'}}>
                                                {arrWeight[key_ob] !== 0  && 
                                                    <i onClick={()=>loadComment(key_ob,'accessories_weights')} style={{cursor: 'pointer'}} className='bx bx-info-circle'></i>
                                                }
                                            </td>
                                        </>
                                    )
                                }
                            }) 
                        }

                        <td style={{borderRight: 0}}><input className="form-control form-control-sm" type="number" step="any" value={arrDimension[key_ob_width] ? arrDimension[key_ob_width] : 0} onBlur={(e) => blurBusketDimension(e,key_ob_width)} onChange={(e) => changeBusketDimension(e,key_ob_width)} /></td>
                        <td style={{borderLeft: 0, textAlign: 'center'}}>
                            {arrDimension[key_ob_width] !== 0  && 
                                <i onClick={()=>loadComment(key_ob_width,'busket_dimensions')} style={{cursor: 'pointer'}} className='bx bx-info-circle'></i>
                            }
                        </td>

                        <td style={{borderRight: 0}}><input className="form-control form-control-sm" type="number" step="any" value={arrDimension[key_ob_height] ? arrDimension[key_ob_height] : 0} onBlur={(e) => blurBusketDimension(e,key_ob_height)} onChange={(e) => changeBusketDimension(e,key_ob_height)} /></td>
                        <td style={{borderLeft: 0, textAlign: 'center'}}>
                            {arrDimension[key_ob_height] !== 0  && 
                                <i onClick={()=>loadComment(key_ob_height,'busket_dimensions')} style={{cursor: 'pointer'}} className='bx bx-info-circle'></i>
                            }
                        </td>

                        <td style={{borderRight: 0}}><input className="form-control form-control-sm" type="number" step="any" value={arrDimension[key_ob_length] ? arrDimension[key_ob_length] : 0} onBlur={(e) => blurBusketDimension(e,key_ob_length)} onChange={(e) => changeBusketDimension(e,key_ob_length)} /></td>
                        <td style={{borderLeft: 0, textAlign: 'center'}}>
                            {arrDimension[key_ob_length] !== 0  && 
                                <i onClick={()=>loadComment(key_ob_length,'busket_dimensions')} style={{cursor: 'pointer'}} className='bx bx-info-circle'></i>
                            }
                        </td>

                    </tr>
                )

                content.push(row)

            }
        }
    }




    let BreadCrumbArray = [
        {
            link: '/accessories-weight',
            name: app.translate['menu_weight_complect'],
            icon: 'bx bx-calculator',
        }
    ]

    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={ app.translate['menu_weight_complect'] } data={BreadCrumbArray}/>
                        
                        
                        <div style={{overflow:'auto'}}>
                            <div className="card">
                                
                                <table className="tableAutoclavePrice">
                                    <tbody>
                                        <tr style={{fontWeight: 600, backgroundColor: 'rgb(240,240,240'}}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colSpan={(arrayAccessories.length * 2)}>{ app.translate['asseccories_weight_title_weight'] }</td>
                                            <td colSpan="6">{ app.translate['asseccories_weight_title_dimension'] }</td>
                                        </tr>
                                        {content.map((row)=>{
                                            return row
                                        })}
                                    </tbody>
                                </table>
                            
                            </div>
                        </div>

                        
                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

            <LogModal 
                changeKeyOb={changeKeyOb} 
                valueKey={valueKey} 
                showLogModal={showLogModal} 
                changeShowLogModal={changeShowLogModal}
                price_log_id={price_log_id}
            ></LogModal>

            <LogCommentModal 
                showLogCommentModal={showLogCommentModal} 
                changeShowLogCommentModal={changeShowLogCommentModal} 
                price_log_comment={price_log_comment}
            ></LogCommentModal>

        </div>

    )
    
})

export default AccessoriesWeight