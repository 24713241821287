export const opros_default = {
    opros_id: 0,
    company_name: '',
    fio: '',
    email: '',
    phone: '',
    razmer_upak_length: '',
    razmer_upak_width: '',
    razmer_upak_height: '',
    weight_netto: '',
    weight_brutto: '',
    time_nagrev: '',
    time_viderjka: '',
    time_ohlajdenia: '',
    time_zagruzka: '',
    productivity: '',
    productivity_hour: '',
    col_work_hour: '',
    ogranichenie_on_time: '',
    temp_product_before_steril: '',
    temp_product_sterilizacia: '',
    temp_on_out_autoclaves: '',
    diametr_autoclaves: '',
    razmer_pod_autoclaves_length: '',
    razmer_pod_autoclaves_width: '',
    razmer_pod_autoclaves_height: '',
    productivity_unit: 'шт / час',
    package_id: '0',
    product_category_id: '0',
    product_id: '0',
    height_between: '0',

    type_ukladka: 0, // 1 - корзины // 2 - поддоны
    is_changed_type_ukladka: 0, // 0 - автоматически решается какой варинат // 1 - вручную изменено
    id_change_type_podacha_water: 0, // 0 - автоматически решается какой варинат // 1 - вручную изменено
    type_autovlave_id: 1,
    col_door: 1,
    type_podacha_water_id: 0,
    weight_upak: '0',

    option_basket: 1,
    option_prokl: 1,
    option_poddon: 1,
    option_telejka: 1,
    option_pnr: 1,
    option_door: 0,
    option_sdoor: 0,
    time_circle: 0,

    is_normal_type_ukladka: 1,
    is_normal_type_podacha_water_id: 1,

    complect_basket_id: 3,
    complect_telejka_id: 3,

    user_col_basket: 0,
    user_col_telejka: 0,

    delivery: 0,
    delivery_price_1: 15000,
    delivery_price_2: 2500,
    
    delivery_country: '',
    delivery_city: '',
    dt_price: 15000,
    tam_posh_price: 4000,
    sertif_price: 0,
    kurs_pr: 5,


}


export const opros_user_setting_default = {
    opros_id: 0,
    col_autoclave_min: 0,
    col_autoclave_max: 0,
    col_basket_min: 0,
    col_basket_max: 0,
    diametr_min: 0,
    diametr_max: 0,
    max_waiting_time: 0,
    basket_weight: 0,
}