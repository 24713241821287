import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Container, Card } from 'react-bootstrap'
import { login } from '../http/userAPI.js'
import { Context } from '../index.js'
import { observer } from 'mobx-react'
import { MAIN_ROUTER } from '../utils/consts.js'

const Signin = observer( () => {

    const {user, app} = useContext(Context)
    const history = useHistory()

    let data = {}

    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')

    if (user.isAuth) {
        history.push(MAIN_ROUTER)
    }

    const sendForm = async () => {

        try {

            data = await login(email,password)

            user.setUser(data)
            user.setIsAuth(true)

            history.push(MAIN_ROUTER)

        } catch(e) {

            console.log(e)
            console.log(e.response) 

        }

    }

    return (

        <Container 
            className="d-flex align-items-center justify-content-center"
            style={{
                height: window.innerHeight
            }}
        >
            <Card
                style={{
                    width: 400,
                    backgroundColor: 'rgb(245,245,255)'
                }}
                className="p-4"
            >   
                <div className="mb-2">
                    <h4>{ app.translate['log_in_title'] }</h4>
                </div>

                <Form.Group className="mb-2">
                    <Form.Control size="sm" onChange={e => setEmail(e.target.value)} type="email" value={email} placeholder={ app.translate['log_in_email'] } />
                </Form.Group>

                <Form.Group className="mb-2">
                    <Form.Control size="sm" onChange={e => setPassword(e.target.value)} type="password" value={password} placeholder={ app.translate['log_in_password'] } />
                </Form.Group>

                <Form.Group className="mb-3"> 
                    <Form.Control onClick={()=>sendForm()} className="btn btn-sm btn-primary" type="button" value={app.translate['log_in']} />
                </Form.Group>

                <div style={{fontSize: '13px'}}>
                    { app.translate['log_in_not_isset_account'] } <a href="/home">{ app.translate['log_in_how_get_access'] }</a>
                </div>

            </Card>

        </Container> 

    )

})

export default Signin