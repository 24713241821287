import { observer } from 'mobx-react'
import { Context } from '../../index.js'
import { useContext } from 'react'


const Footer = observer(() => {

    const { app } = useContext(Context)

    return (
        <footer className="page-footer">
            <p className="mb-0">Copyright © 2021. { app.translate['common_copyright'] }</p>
        </footer>
    )
})

export default Footer