import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'

const HomeBar = observer((props) => {

    const { app } = useContext(Context)

    return (
        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-4">
            <div className="col">
                <div className="card radius-10 bg-gradient-cosmic">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="me-auto">
                                <p className="mb-0 text-white">{ app.translate['total_oproses'] }</p>
                                <h4 className="my-1 text-white">{props.col_opros}</h4>
                            </div>
                            <div id="chart1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10 bg-gradient-ibiza">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <p className="mb-0 text-white">{ app.translate['summ_on_rub'] }</p>
                            <h4 className="my-1 text-white">₽ 485 000 000</h4>
                        </div>
                        <div id="chart2"></div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10 bg-gradient-ohhappiness">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <p className="mb-0 text-white">{ app.translate['summ_on_euro'] }</p>
                            <h4 className="my-1 text-white">€ 4 600 000</h4>
                        </div>
                        <div id="chart3"></div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10 bg-gradient-kyoto">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <p className="mb-0 text-dark">{ app.translate['summ_on_usd'] }</p>
                            <h4 className="my-1 text-dark">$ 5 000 000</h4>
                        </div>
                        <div id="chart4"></div>
                    </div>
                </div>
                </div>
            </div> 
        </div>
    )
})

export default HomeBar