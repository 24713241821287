import { BrowserRouter } from 'react-router-dom'
import AppRouter from './components/AppRouter.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import {observer} from 'mobx-react'
import { useContext, useState, useEffect } from 'react'
import {Context} from './index.js'
import { check } from './http/userAPI.js'
import { getLang, getSystem } from './http/catalogAPI'
import { Spinner } from 'react-bootstrap'
import { createObjectLang } from './utils/fnc'


const App = observer(() =>{

  const {user} = useContext(Context)
  const {app} = useContext(Context)
  const [loading,setLoading] = useState(true)
  
  useEffect(async ()=>{

    let lang_file = await getLang()
    let system = await getSystem()
    let translate = createObjectLang(lang_file,app.lang)
    app.setLangFile(lang_file)
    app.setTranslate(translate)
    app.setSystem(system)

    check().then( data => {
      user.setUser(data)
      user.setIsAuth(true)
    }).finally(()=>{
      setLoading(false)
    })

  }, [user, app])

  if (loading) {
    return (
      <div>
        Идет загрузка ....
      </div>
    )
  }

  return (
    <BrowserRouter>
      <AppRouter/>
    </BrowserRouter>
  );
  
})

export default App;
