import {
    MAIN_ROUTER,
    SIGNIN_ROUTER,
    CALCULATE_DATA_ROUTER,
    CALCULATE_LIST_ROUTER,
    CALCULATE_OPROS_ROUTER,
    PRODUCT_CATEGORY_ROUTER,
    AUTOCLAVE_ROUTER,
    PACKAGES_ROUTER,
    ACCESSORIES_ROUTER,
    CALCULATE_ITOG,
    ACCESSORIES_WEIGHT,
    CALCULATE_SCHEMA,
    CREATE_DOCX_ROUTER,
    SPECIFICATION_PRICE_ROUTER,
    LAND_SETTING,
    ACCESSORIES_NAME_ROUTER,
    SPECIFICATION_ROUTER,
    CALCULATE_PRICE_TABLE_ROUTER,
    IMAGE_FILE_ROUTER,
} from './utils/consts.js'
import Signin from './components/Signin.js'
import CalculateData from './components/calculate/CalculateData.js'
import CalculateList from './components/calculate/CalculateList.js'
import CalculateItog from './components/calculate/CalculateItog.js'
import CalculatePriceTable from './components/calculate/CalculatePriceTable'
import CalculateSchema from './components/calculate/CalculateSchema.js'
import Autoclave from './components/catalog/Autoclave.js'
import Accessories from './components/catalog/Accessories.js'
import AccessoriesWeight from './components/catalog/AccessoriesWeight.js'
import ProductCategory from './components/catalog/ProductCategory.js'
import SpecificationPrice from './components/catalog/SpecificationPrice.js'
import AccessoriesCatalog from './components/catalog/AccessoriesCatalog.js'
import SpecificationCatalog from './components/catalog/SpecificationCatalog.js'
import Packages from './components/catalog/Packages.js'
import LangSetting from './components/common/LangSetting.js'
import ImageFile from './components/common/ImageFile.js'

export const authRoutes = [
    {
        path: MAIN_ROUTER,
        Component: CalculateList
    },
    {
        path: PACKAGES_ROUTER,
        Component: Packages
    },
    {
        path: PRODUCT_CATEGORY_ROUTER,
        Component: ProductCategory
    },
    {
        path: CALCULATE_ITOG,
        Component: CalculateItog
    },
    {
        path: CALCULATE_DATA_ROUTER,
        Component: CalculateData
    },
    {
        path: CALCULATE_LIST_ROUTER,
        Component: CalculateList
    },
    {
        path: CALCULATE_OPROS_ROUTER,
        Component: CalculateData
    },
    {
        path: AUTOCLAVE_ROUTER,
        Component: Autoclave
    },
    {
        path: ACCESSORIES_ROUTER,
        Component: Accessories
    },
    {
        path: ACCESSORIES_WEIGHT,
        Component: AccessoriesWeight
    },
    {
        path: CALCULATE_SCHEMA,
        Component: CalculateSchema
    },
    {
        path: SPECIFICATION_PRICE_ROUTER,
        Component: SpecificationPrice,
    },
    {
        path: LAND_SETTING,
        Component: LangSetting,
    },
    {
        path: ACCESSORIES_NAME_ROUTER,
        Component: AccessoriesCatalog,
    },
    {
        path: SPECIFICATION_ROUTER,
        Component: SpecificationCatalog,
    },
    {
        path: CALCULATE_PRICE_TABLE_ROUTER,
        Component: CalculatePriceTable,
    }
] 

export const publicRoutes = [
    {
        path: SIGNIN_ROUTER,
        Component: Signin
    },
    {
        path: IMAGE_FILE_ROUTER,
        Component: ImageFile
    }
] 