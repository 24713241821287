export const prettryNumber = (num) => {
    return num
}

export const createObjectLang = (arr,lang) => {
    let ob = {}
    arr.map((l) => {
        ob[l.key] = l['name_' + lang]
    })
    return ob
}