import { $authHost, $host } from './index.js'

export const cancelPriceLog = async (price_log_id) => {

    const {data} = await $authHost.post('api/catalog/cancele_price_log', {
        price_log_id: price_log_id
    })

    return data

}

export const getComment = async (key_ob,table) => {
    
    const {data} = await $authHost.post('api/catalog/get_comment', {
        key_ob: key_ob,
        table: table,
    })

    return data
}

export const saveComment = async (price_log_id, comment) => {
    
    const {data} = await $authHost.post('api/catalog/save_comment', {
        price_log_id: price_log_id,
        comment: comment
    })

    return data
}

export const setPriceAutoclave = async (key_ob,price) => {

    const {data} = await $authHost.post('api/catalog/set_price_autoclave', {
        key_ob: key_ob,
        price: price
    })

    return data

}


export const setImageSpecification = async (key_ob, file) => {

    const formData = new FormData()
    formData.append('file', file)
    formData.append('key_ob', key_ob)
    const { data } = await $authHost.post('api/catalog/set_image_specification', formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    return data
}

export const setImageWeightAccessories = async (key_ob, file) => {

    const formData = new FormData()
    formData.append('file', file)
    formData.append('key_ob', key_ob)
    const { data } = await $authHost.post('api/catalog/set_image_weight_accessories', formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    return data
}


export const setPriceSpecification = async (key_ob,price) => {

    const {data} = await $authHost.post('api/catalog/set_price_specification', {
        key_ob: key_ob,
        price: price
    })

    return data

}

export const getPriceAutoclave = async () => {

    const {data} = await $authHost.get('api/catalog/get_price_autoclave')
    return data

}

export const getPriceSpecification = async () => {

    const {data} = await $authHost.get('api/catalog/get_price_specification')
    return data

}

export const getSpecification = async () => {

    const {data} = await $authHost.get('api/catalog/get_specification')
    return data

}

export const getAccessories = async (type = 'ALL') => {

    const {data} = await $authHost.get('api/catalog/get_accessories?type='+type)
    return data

}

export const getBusketDimension = async () => {

    const {data} = await $authHost.get('api/catalog/get_busket_dimension')
    return data

}


export const getPriceAccessories = async () => {

    const {data} = await $authHost.get('api/catalog/get_price_accessories')
    return data

}

export const setPriceAccessories = async (key_ob,price) => {

    const {data} = await $authHost.post('api/catalog/set_price_accessories', {
        key_ob: key_ob,
        price: price
    })

    return data

}



export const getWeightAccessories = async () => {

    const {data} = await $authHost.get('api/catalog/get_weight_accessories')
    return data

}

export const setWeightAccessories = async (key_ob,weight) => {

    const {data} = await $authHost.post('api/catalog/set_weight_accessories', {
        key_ob: key_ob,
        weight: weight
    })

    return data

}




export const setBusketDimension = async (key_ob,val) => {

    const {data} = await $authHost.post('api/catalog/set_busket_dimension', {
        key_ob: key_ob,
        val: val
    })

    return data

}


export const getProductCategory = async () => {

    const {data} = await $authHost.get('api/catalog/get_product_category')
    return data

}

export const deleteProduct = async (product_id) => {
    const {data} = await $authHost.post('api/catalog/delete_product', {
        product_id:product_id
    })
    return data
}

export const deleteProductCategory = async (product_category_id) => {
    const {data} = await $authHost.post('api/catalog/delete_product_category', {
        product_category_id:product_category_id
    })
    return data
}

export const saveProductCategory = async (product_category_id,dataApi) => {

    const {data} = await $authHost.post('api/catalog/save_product_category', {
        product_category_id: product_category_id,
        data: dataApi
    })

    return data

}



export const setLang = async (lang_id,key,value) => {

    const {data} = await $authHost.post('api/catalog/set_lang', {
        lang_id: lang_id,
        key: key,
        value: value
    })

    return data

}

export const removeLang = async (lang_id) => {

    const {data} = await $authHost.post('api/catalog/remove_lang',{
        lang_id
    })

}

export const addLang = async (key,name_ru,name_en,name_es,comment) => {

    const {data} = await $authHost.post('api/catalog/add_lang', {
        key,
        name_ru,
        name_en,
        name_es,
        comment
    })

    return data

}


export const getLang = async () => {

    const {data} = await $host.get('api/catalog/get_lang')
    return data

}


export const getSystem = async () => {

    const {data} = await $host.get('api/catalog/get_system')
    return data

}


export const saveProduct = async (product_id,product_category_id,dataApi) => {

    const {data} = await $authHost.post('api/catalog/save_product', {
        product_id: product_id,
        product_category_id: product_category_id,
        data: dataApi,
    })

    return data

}



export const getPackages = async () => {

    const {data} = await $authHost.get('api/catalog/get_packages')
    return data

}

export const deletePackages = async (package_id) => {
    const {data} = await $authHost.post('api/catalog/delete_packages', {
        package_id:package_id
    })
    return data
}

export const savePackages = async (package_id,dataApi) => {

    const {data} = await $authHost.post('api/catalog/save_packages', {
        package_id: package_id,
        data: dataApi,
    })

    return data

}





export const deleteAccessories = async (id) => {
    const {data} = await $authHost.post('api/catalog/delete_accessories', {
        id:id
    })
    return data
}

export const saveAccessories = async (id,dataApi) => {

    const {data} = await $authHost.post('api/catalog/save_accessories', {
        id: id,
        data: dataApi,
    })

    return data

}





export const deleteSpecification = async (specification_id) => {
    const {data} = await $authHost.post('api/catalog/delete_specification', {
        specification_id:specification_id
    })
    return data
}

export const saveSpecification = async (specification_id,dataApi) => {

    const {data} = await $authHost.post('api/catalog/save_specification', {
        specification_id: specification_id,
        data: dataApi,
    })

    return data

}