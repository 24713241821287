import {useEffect, useState, useContext} from 'react'
import { Button, Modal } from 'react-bootstrap';
import { saveComment } from '../../../http/catalogAPI.js'
import {printDate} from '../../helpers/common.js'
import { observer } from 'mobx-react'
import { Context } from '../../../index.js'

const LogModal = observer((data) => {

    const { app } = useContext(Context)

    let showLogCommentModal = data.showLogCommentModal
    let price_log_comment_temp = data.price_log_comment

    const [show, setShow] = useState(showLogCommentModal)
    const [price_log_comment, setPriceLogComment] = useState(price_log_comment_temp)

    useEffect(()=>{
        setShow(showLogCommentModal)
        setPriceLogComment(price_log_comment_temp)
    },[showLogCommentModal,price_log_comment_temp])


    const handleClose = async () => {
        setShow(false)
        data.changeShowLogCommentModal(false)
    }


    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 18}}>{ app.translate['comment_change_title'] }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <table className="tableComment">
                        <tr>
                            <td>ID</td>
                            <td>{ app.translate['modal_comment_date'] }</td>
                            <td>{ app.translate['modal_comment_value'] }</td>
                            <td>{ app.translate['modal_comment_comment'] }</td>
                            <td>{ app.translate['modal_comment_user'] }</td>
                        </tr>
                    {price_log_comment.map((one) => {
                        return (
                            <tr>
                                <td>{one.price_log_id}</td>
                                <td>{printDate(one.createdAt,0)}</td>
                                <td>{one.price}</td>
                                <td>{one.comment}</td>
                                <td>{one.user.name}</td>
                            </tr>
                        )
                    })}
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    );

})

export default LogModal