import { observable } from 'mobx'

export const type_autovlave_list = observable([
    {
        type_autovlave_id: 1,
        name: 'горизонтальный статический',
        key: 'type_autovlave_id_1'
    },
    {
        type_autovlave_id: 2,
        name: 'горизонтальный роторный',
        key: 'type_autovlave_id_2'
    },
    {
        type_autovlave_id: 3,
        name: 'вертикальный',
        key: 'type_autovlave_id_3'
    },
])

export const col_door_list = observable([
    {
        col_door: 1
    },
    {
        col_door: 2
    }
])

export const type_podacha_water_list = observable([
    {
        type_podacha_water_id: 1,
        name: 'водный каскад',
        key: 'type_podacha_water_id_1'
    },  
    {
        type_podacha_water_id: 2,
        name: 'водный душ',
        key: 'type_podacha_water_id_2'
    }
])

export const type_ukladka_list = observable([
    {
        type_ukladka_id: 1,
        name: 'корзины',
        key: 'type_ukladka_id_1'
    },  
    {
        type_ukladka_id: 2,
        name: 'поддоны',
        key: 'type_ukladka_id_2'
    }
])

export const complect_basket_list = observable([
    {
        complect_basket_id: 1,
        name: 'суммарное кол-во корзин во всех автоклавах',
        key: 'complect_basket_id_1'
    },  
    {
        complect_basket_id: 2,
        name: 'суммарное кол-во корзин во всех автоклавах + 1 комплект',
        key: 'complect_basket_id_2'
    },  
    {
        complect_basket_id: 3,
        name: 'суммарное кол-во корзин во всех автоклавах + 2 комплекта',
        key: 'complect_basket_id_3'
    },  
    {
        complect_basket_id: 4,
        name: 'другое',
        key: 'complect_basket_id_4'
    },  
])


export const complect_telejka_list = observable([  
    {
        complect_telejka_id: 2,
        name: '1 комплект',
        key: 'complect_telejka_id_2'
    },  
    {
        complect_telejka_id: 3,
        name: '2 комплекта',
        key: 'complect_telejka_id_3'
    },  
    {
        complect_telejka_id: 5,
        name: 'количество равное кол-ву корзин',
        key: 'complect_telejka_id_5'
    }, 
    {
        complect_telejka_id: 4,
        name: 'другое',
        key: 'complect_telejka_id_4'
    }, 
])