import { observable } from 'mobx'

export const layout = observable({
    '1': {
        name: 'Горизонтальный',
        key: 'horizontal_autoclave'
    },
    '2': {
        name: 'Вертикальный',
        key: 'vertical_autoclave'
    }
})

export const layout_type = observable({
    '1': {
        '1': {
            name: 'Статический',
            key: 'static_autoclave'
        },
        '2': {
            name: 'Ротационный',
            key: 'rotation_autoclave'
        },
    },
    '2': {
        '3': {
            name: 'Статический',
            key: 'static_autoclave'
        },
    }
})

export const type_d = {
    '1': [
        700,
        1000,
        1300,
        1400,
        1500,
        1800,
        2000
    ],
    '2': [
        700,
        1000,
        1300,
        1400,
        1500,
        1800,
        2000
    ],
    '3': [
        700,
        1000,
        1300
    ]
}


export const col_basket_array = [1,2,3,4,5,6,7,8];

export const getArrData = (layout, layout_type, type_d, col_basket_array, arr_res) => {
    let arr = []
    for (let layout_id in layout) {
        for (let type_id in layout_type[layout_id]) {
            for (let d_key in type_d[type_id]) {
                let d = type_d[type_id][d_key]
                for (let cb_key in col_basket_array) {
                    let col_basket = col_basket_array[cb_key]
                    let key_ob = layout_id + "_" + type_id + "_" + d + "_" + col_basket
                    arr[key_ob] = arr_res[key_ob] ? arr_res[key_ob] : 0
                }
            }
        }
    }
    return arr
}

export const getArrDataSpecification = (layout, layout_type, type_d, col_basket_array, type_podacha_water_id, specification_id, arr_res) => {
    let arr = []
    for (let layout_id in layout) {
        for (let type_id in layout_type[layout_id]) {
            for (let d_key in type_d[type_id]) {
                let d = type_d[type_id][d_key]
                for (let cb_key in col_basket_array) {
                    let col_basket = col_basket_array[cb_key]
                    let key_ob = layout_id + "_" + type_id + "_" + d + "_" + col_basket + "_" + type_podacha_water_id + "_" + specification_id
                    arr[key_ob] = arr_res[key_ob] ? arr_res[key_ob] : 0
                }
            }
        }
    }
    return arr
}

export const getArrDataAccessories = (layout, layout_type, type_d, arr_accessories, arr_res) => {
    console.log(arr_accessories)
    let arr = []
    for (let layout_id in layout) {
        for (let type_id in layout_type[layout_id]) {
            for (let d_key in type_d[type_id]) {
                let d = type_d[type_id][d_key]
                for (let i=0; i<arr_accessories.length; i++) {
                    let id = arr_accessories[i].id
                    let key_ob = layout_id + "_" + type_id + "_" + d + "_" + id
                    arr[key_ob] = arr_res[key_ob] ? arr_res[key_ob] : 0
                }
            }
        }
    }
    return arr
}

export const getArrBusketDimension = (layout, layout_type, type_d, arr_res) => {
    let arr = []
    for (let layout_id in layout) {
        for (let type_id in layout_type[layout_id]) {
            for (let d_key in type_d[type_id]) {
                let d = type_d[type_id][d_key]

                let key_ob_width = layout_id + "_" + type_id + "_" + d + "_width"
                let key_ob_height = layout_id + "_" + type_id + "_" + d + "_height"
                let key_ob_length = layout_id + "_" + type_id + "_" + d + "_length"
                arr[key_ob_width] = arr_res[key_ob_width] ? arr_res[key_ob_width] : 0
                arr[key_ob_height] = arr_res[key_ob_height] ? arr_res[key_ob_height] : 0
                arr[key_ob_length] = arr_res[key_ob_length] ? arr_res[key_ob_length] : 0
            
            }
        }
    }
    return arr
}