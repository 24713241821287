import { useEffect, useState, useContext } from 'react'
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import InputSpecifications from './view/InputSpecifications.js'
import BreadCrumb from '../common/BreadCrumb.js'
import { getSpecification } from '../../http/catalogAPI.js'
import { observer } from 'mobx-react'
import { Context } from '../../index.js'

const SpecificationCatalog = observer( () => {

    const { app } = useContext(Context)

    const [specifications,setSpecifications] = useState([])
    const [reload,setReload] = useState(true)

    const updateSpecification = () => setReload(!reload)   
    
    useEffect( async () => {
        let array_temp = await getSpecification()
        setSpecifications(array_temp)
    },[reload])

    let BreadCrumbArray = [
        {
            link: '/specification-name',
            name: app.translate['menu_reference_specification'],
            icon: 'bx bx-calculator',
        }
    ]



    return (

        <div>
            
            <div className="wrapper">

                <Header/>

                <div className="page-wrapper">
                    <div className="page-content">

                        <BreadCrumb title={ app.translate['menu_reference_specification'] } data={BreadCrumbArray}/>
                        
                        <div style={{overflow:'auto'}}>
                            <div className="card p-4">

                                <InputSpecifications update={updateSpecification}></InputSpecifications>
                                
                                {specifications.map((data) => {
                                    return (
                                        <div className="">
                                            <InputSpecifications key={data.specification_id} update={updateSpecification} name={data.name} name_ru={data.name_ru} name_en={data.name_en} name_es={data.name_es} specification_id ={data.specification_id }></InputSpecifications>                
                                         </div>
                                    )
                                })}


                                

                            </div>
                        </div>

                        
                    </div>
                </div>

                
                <div className="overlay toggle-icon"></div>
                
                <Footer/>

            </div>

        </div>

    )
    
} )

export default SpecificationCatalog